import React, {useState, useRef, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {ListContainer, List, ListItem, NoResultContainer, listItemHeight} from './StyledList';
import {LinkButton} from '../../Button';
import Spinner from '../../Spinner/Spinner';

const PREFIX = 'SearchList';

const classes = {
  root: `${PREFIX}-root`,
  popper: `${PREFIX}-popper`,
};

const StyledListContainer = styled(ListContainer)(({theme}) => ({
  [`&.${classes.root}`]: {
    backgroundColor: '#FFF',
  },

  [`& .${classes.popper}`]: {
    zIndex: theme.zIndex.popper,
  },
}));

const PopperListContainer = styled(List)`
  position: relative;
`;

const SearchList = ({
  name,
  className,
  renderLabel = (label) => label,
  isFromSearch,
  placeholder,
  items = [],
  onSelected = (item) => {},
  onBlur = () => {},
  onClick = (text) => {},
  onChange = (text) => {},
}) => {
  const [open, setOpen] = useState(false);
  const searchBoxRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const containerRef = useRef(null);
  const [cursor, setCursor] = useState(0);
  const [listRefs, setListRefs] = useState(0);
  const [showLoading, setShowLoading] = useState(false);

  //If there are searchText in the searchbox, but the facet items are not returned without applying the search text, we will trigger another facet search
  const shouldSearchAgainWithText = searchText && !isFromSearch;
  useEffect(() => {
    if (shouldSearchAgainWithText) {
      onChange(searchText);
      const timeoutID = setTimeout(() => {
        setShowLoading(true);
      }, 200);

      return () => clearTimeout(timeoutID);
    }
  }, [shouldSearchAgainWithText, onChange, searchText]);

  useEffect(() => {
    if (items.length > 0) {
      setListRefs(items.map((x) => React.createRef()));
    }
  }, [items]);

  useEffect(() => {
    if (items.length === 0 || cursor >= items.length) {
      setCursor(0);
    } else if (listRefs && listRefs.length > cursor && listRefs[cursor].current !== null) {
      listRefs[cursor].current.scrollIntoView({block: 'nearest'});
    }
  }, [items, cursor, listRefs]);
  function handleKeyDown(e) {
    if (items.length <= 0) {
      setCursor(0);
    }
    if (cursor > items.length) {
      setCursor(0);
    }
    if (e.key === 'ArrowUp' && cursor > 0) {
      setCursor((prevCursor) => {
        let cursor = prevCursor - 1;
        listRefs[cursor].current.scrollIntoView({block: 'nearest'});
        return cursor;
      });
    } else if (e.key === 'ArrowDown' && cursor < items.length - 1) {
      setCursor((prevCursor) => {
        let cursor = prevCursor + 1;
        listRefs[cursor].current.scrollIntoView({block: 'nearest'});
        return cursor;
      });
    } else if (e.key === 'Enter' && open && items.length > 0) {
      onSelected(items[cursor]);
    }
  }
  function clearSearch() {
    setSearchText('');
  }
  function getListItems(items) {
    if (shouldSearchAgainWithText) {
      if (!showLoading) {
        return null;
      }
      return (
        <ListItem sx={{height: '90px'}}>
          <Spinner loading={true} />
        </ListItem>
      );
    }

    if (items.length > 0) {
      return items.map((item, index) => {
        return (
          <ListItem
            component="button"
            key={item.label}
            ref={listRefs[index]}
            selected={cursor === index}
            onClick={() => {
              setCursor(index);
              onSelected(item);
            }}
            onMouseDown={(event) => event.preventDefault()}
            sx={{width: '100%'}}
          >
            <ListItemIcon>
              <Checkbox edge="start" checked={item.isRefined} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText>
              {renderLabel(item.label)} ({item.count})
            </ListItemText>
          </ListItem>
        );
      });
    } else {
      return (
        <ListItem onMouseDown={(event) => event.preventDefault()}>
          <NoResultContainer>
            We haven't found any options that match the text you entered.
          </NoResultContainer>
        </ListItem>
      );
    }
  }
  function getDropdownListPosition() {
    if (searchBoxRef.current == null || showLoading) {
      return 'bottom-start';
    }
    const rect = searchBoxRef.current.getBoundingClientRect();
    if (items.length > 0) {
      if (rect.y + items.length * listItemHeight > window.innerHeight) {
        return 'top-start';
      }
    }
    return 'bottom-start';
  }
  // use form here to solve the autocomplete issue, for some reason, the autocomplete won't work for all inputs, so add this work around
  // https://stackoverflow.com/questions/48304062/material-ui-textfield-disable-browser-autocomplete/60181622#60181622
  return (
    <StyledListContainer ref={containerRef}>
      <form noValidate>
        <TextField
          onKeyDown={handleKeyDown}
          classes={{root: classes.root}}
          type="text"
          variant="outlined"
          inputRef={searchBoxRef}
          fullWidth
          name={name}
          className={className}
          placeholder={placeholder}
          autoComplete="off"
          onBlur={(event) => {
            setOpen(false);
            setSearchText('');
            setCursor(0);
          }}
          onClick={() => {
            searchBoxRef.current.focus();
            onClick(searchText);
            setOpen(!open);
          }}
          value={searchText}
          onChange={(event) => {
            setOpen(true);
            const text = event.currentTarget.value;
            setSearchText(text);
            onChange(text);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchText && (
                  <LinkButton type="button" onClick={clearSearch}>
                    Clear
                  </LinkButton>
                )}
                {open ? <ExpandLess /> : <ExpandMore />}
              </InputAdornment>
            ),
          }}
        />
      </form>

      {open && (
        <Popper
          open={open}
          anchorEl={searchBoxRef.current}
          disablePortal={true}
          placement={getDropdownListPosition()}
          className={classes.popper}
          sx={{width: containerRef.current.offsetWidth}}
        >
          <PopperListContainer onMouseDown={(event) => event.preventDefault()}>
            {getListItems(items)}
          </PopperListContainer>
        </Popper>
      )}
    </StyledListContainer>
  );
};

export default SearchList;
