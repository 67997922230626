import React, {useContext} from 'react';
import qs from 'qs';
import LoadingContext from '../../app/LoadingContext';
import {OutlinedButton} from '../Button';
import {api, get} from '../../utils/Request';
import Consts from '../../app/Consts';
import config from '../../app/Config';
import {alertService, defaultAlertId} from '../../app/AlertService';
import {ClaimSideFilters} from '../../app/claimsReducer';
import {Order} from '../../types';

type ClaimsDownloadRequest = {
  agreementType?: string[];
  rebateType?: string[];
  departmentNumber?: number[];
  financeAccountId?: number[];
  ownedByStaffCode?: number;
  claimVendorCode?: string;
  claimFrequency?: string;
  startAt?: string;
  endAt?: string;
  paymentPriority?: string;
  excludeClaimVendorCode?: string;
  onlyReturnAdjustedClaims?: boolean;
  status?: string;
  searchText?: string;
  orderBy?: string;
};

type DownloadClaimsCsvButtonProps = {
  filters: ClaimSideFilters;
  searchText: string | null;
  order: Order;
  ownerStaffCodeOverride?: number;
};

export const downloadClaimsCsvButtonTestId = 'claims-download-csv-button';

const DownloadClaimsCsvButton = ({
  filters,
  searchText,
  order,
  ownerStaffCodeOverride,
}: DownloadClaimsCsvButtonProps) => {
  const {showLoading, hideLoading} = useContext(LoadingContext);

  /**
   * Calls claims download api endpoint and creates a hidden anchor tag
   * with an inline object url to trigger browser to download the file
   */
  const downloadCsvReport = () => {
    const requestParams = buildDownloadClaimsRequest(
      filters,
      searchText,
      order,
      ownerStaffCodeOverride
    );

    showLoading();
    get(api(Consts.Api.ClaimsDownload), {
      params: {
        entityCode: config.entityCode,
        ...requestParams,
      },
      responseType: 'blob',
      paramsSerializer: (params: any) =>
        qs.stringify(params, {skipNulls: true, arrayFormat: 'repeat'}),
    })
      .then(({data}) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(new Blob([data]));
        link.href = url;
        link.download = `${ownerStaffCodeOverride != null ? 'My' : 'All'} Claims.csv`;
        link.click();
        link.remove();
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          message: error.message,
          response: error.response,
          id: defaultAlertId,
        });
      });
  };
  return (
    <OutlinedButton onClick={downloadCsvReport} data-testid={downloadClaimsCsvButtonTestId}>
      Download CSV
    </OutlinedButton>
  );
};

/**
 * Builds the set of request parameters for the csv download
 * using the current filters, search text and order settings from props
 * @returns object with parameter values
 */
export function buildDownloadClaimsRequest(
  filters: ClaimSideFilters,
  searchText: string | null,
  order: Order,
  ownerStaffCodeOverride?: number
): ClaimsDownloadRequest {
  const orderBy =
    order?.orderBy && order?.orderByDirection
      ? `${order.orderBy}:${order.orderByDirection}`
      : undefined;

  const agreementType = filters.types?.map((x) => x.value.agreementType);
  const rebateType = filters.types?.map((x) => x.value.rebateType);
  const departmentNumber = filters.departments?.map((x) => x.number);
  const financeAccountId = filters.financeAccounts?.map((x) => x.id);

  return {
    agreementType:
      Array.isArray(agreementType) && agreementType.length > 0 ? agreementType : undefined,
    rebateType: Array.isArray(rebateType) && rebateType.length > 0 ? rebateType : undefined,
    departmentNumber:
      Array.isArray(departmentNumber) && departmentNumber.length > 0 ? departmentNumber : undefined,
    financeAccountId:
      Array.isArray(financeAccountId) && financeAccountId.length > 0 ? financeAccountId : undefined,
    claimVendorCode: filters.claimVendor?.code,
    claimFrequency: filters.claimFrequency?.value,
    startAt: filters.startAt ?? undefined,
    endAt: filters.endAt ?? undefined,
    ownedByStaffCode: ownerStaffCodeOverride ?? filters.owner?.code,
    paymentPriority: filters.paymentPriority,
    excludeClaimVendorCode: filters.excludeNartaInternational ? Consts.Narta.Code : undefined,
    onlyReturnAdjustedClaims: filters.onlyReturnAdjustedClaims ?? undefined,
    status: filters.status ?? undefined,
    searchText: searchText !== null ? searchText : undefined,
    orderBy,
  };
}

export default DownloadClaimsCsvButton;
