import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import {AxiosResponse} from 'axios';
import {useFormikContext} from 'formik';
import * as Yup from 'yup';
import isAfter from 'date-fns/isAfter';
import isPast from 'date-fns/isPast';
import {
  DateType,
  daysAfterTodayInTZ,
  daysBeforeTodayInTZ,
  daysBetweenInTZ,
  formatDate,
  isValidDate,
} from '../../utils/DateUtils';
import {post, api, put, del, get} from '../../utils/Request';
import LoadingContext from '../../app/LoadingContext';
import {alertService, AlertType, defaultAlertId} from '../../app/AlertService';
import config from '../../app/Config';
import Consts from '../../app/Consts';
import {
  ClaimListResponse,
  CreateSPIVRequest,
  Department,
  EditSPIVRequest,
  EntityActionType,
  SPIVFormBag,
  SPIVResponse,
  ValidSPIVFormValues,
} from '../../types';
import InputField from '../Form/InputField';
import FieldLabel from '../Form/FieldLabel';
import FieldGrid from '../Form/FieldGrid';
import LocationField from '../Form/LocationField';
import DatePickerField from '../Form/DatePickerField';
import FormStep from '../Form/FormStep';
import StepperFormActionSection from '../Form/StepperFormActionSection';
import SelectField from '../Form/SelectField';
import {SPIVClaimVendorChangeConfirmation} from '../Modal';
import ActiveClaimWarning from '../Claim/ActiveClaimWarning';
import useDelayLoading from '../Hook/useDelayLoading';
import {ErrorBox} from '../Alert';
import SPIVNartaSwitch from './SPIVNartaSwitch';
import SPIVClaimVendorSearch from './SPIVClaimVendorSearch';
import {spivDisabled} from './spivUtils';

const PREFIX = 'StepSPIVDetails';

const classes = {
  hidden: `${PREFIX}-hidden`,
};

export const noFutureStartAtValidationSchema = () =>
  Yup.object().shape({
    startAt: Yup.date().test('future start check', function (value) {
      if (value && isAfter(value, new Date())) {
        return this.createError({
          message: `The start date (${formatDate(value)}) cannot be set in the future`,
        });
      }
      return true;
    }),
  });

const Root = styled('div')(() => ({
  [`& .${classes.hidden}`]: {
    display: 'none',
  },
}));

type Props = {
  step: number;
  title: string;
  validationSchema?: any;
  totalStep: number;
  onBack: (values: ValidSPIVFormValues, bag: SPIVFormBag) => void;
  onNext: (values: ValidSPIVFormValues, bag: SPIVFormBag) => void;
  style?: React.CSSProperties;
};

const StepSPIVDetails: FC<Props> = ({step, title, totalStep, onBack, onNext}) => {
  const [departments, setDepartments] = useState<Department[]>([]);
  const [showNoFutureStartInfo, setShowNoFutureStartInfo] = useState(false);
  const [showNoFutureStartError, setShowNoFutureStartError] = useState(false);
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const bag = useFormikContext<ValidSPIVFormValues>();
  const initialStartDate = useRef<Date>();
  const initialEndDate = useRef<Date>();

  const {
    setFieldValue,
    values: {
      claimsFetched,
      claimVendorCode,
      departmentNumber,
      endAt,
      hasActiveClaim,
      hasRaisedClaim,
      id,
      spivValueCount,
      startAt,
      status,
    },
  } = bag;
  const [departmentLoading, setDepartmentLoading] = useDelayLoading(false);
  const showStartDateDaysBeforeWarning =
    startAt && daysBeforeTodayInTZ(startAt) >= config.dealStartDateWarningDaysBeforeToday;
  const showStartDateDaysAfterWarning =
    startAt && daysAfterTodayInTZ(startAt) >= config.dealStartDateWarningDaysAfterToday;
  const showDateRangeWarning =
    startAt && endAt && daysBetweenInTZ(endAt, startAt) > config.dealDateRangeWarningDaysBetween;

  const updateFormValues = (updatedValues: Partial<ValidSPIVFormValues>) => {
    Object.entries(updatedValues).forEach(([key, value]) => {
      setFieldValue(key, value);
    });
  };

  const {
    fields: disabledFields,
    actions: {disableUpdateOnNextStepOne},
  } = spivDisabled(bag.values);

  useEffect(() => {
    if (!initialStartDate.current && startAt) {
      initialStartDate.current = new Date(startAt);
    }
  }, [startAt]);

  useEffect(() => {
    if (!initialEndDate.current && endAt) {
      initialEndDate.current = new Date(endAt);
    }
  }, [endAt]);

  const getUpdatedFormValues = (responseData: SPIVResponse) => ({
    id: responseData.id,
    claimVendorSuppliers: responseData.claimVendorSuppliers,
  });

  const fetchBuyerDepartments = useCallback(async () => {
    try {
      const response = await get(api(Consts.Api.BuyerDepartments), {
        params: {entityCode: config.entityCode},
      });
      if (response.data.length > 0) {
        // for new deals, set default department to first buyer department
        setFieldValue('departmentNumber', response.data[0].number);
      }
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    }
  }, [setFieldValue]);

  useEffect(() => {
    if (!id) {
      fetchBuyerDepartments();
    }
  }, [id, fetchBuyerDepartments]);

  const fetchDepartments = useCallback(async () => {
    try {
      setDepartmentLoading(true);
      const response = await get(api(Consts.Api.Departments), {
        params: {entityCode: config.entityCode},
      });
      setDepartments(response.data);
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      setDepartmentLoading(false);
    }
  }, [setDepartments, setDepartmentLoading]);

  useEffect(() => {
    if (departments?.length === 0) {
      fetchDepartments();
    }
  }, [setDepartmentLoading, departments, fetchDepartments]);

  const fetchClaims = useCallback(async () => {
    try {
      showLoading();
      const response: AxiosResponse<ClaimListResponse> = await get(
        api(Consts.Api.DealAgreementClaims.replace(':id', `${id}`))
      );
      setFieldValue('claimsFetched', true);
      if (response.data?.data?.length > 0) {
        setFieldValue('claims', response.data?.data);
      }
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  }, [id, setFieldValue, showLoading, hideLoading]);

  useEffect(() => {
    if (id && !claimsFetched) {
      fetchClaims();
    }
  }, [id, claimsFetched, fetchClaims]);

  const startInPast = initialStartDate.current && isPast(initialStartDate.current);
  const noFutureStartCriteriaMet = !!startInPast && status !== Consts.AgreementStatusEnum.Draft;

  useEffect(() => {
    if (startAt) {
      if (isValidDate(startAt)) {
        const showError = noFutureStartCriteriaMet && isAfter(new Date(startAt), new Date());
        setShowNoFutureStartError(showError);
        if (endAt) {
          if (isValidDate(endAt)) {
            setShowNoFutureStartInfo(noFutureStartCriteriaMet);
          }
        }
      }
    }
  }, [noFutureStartCriteriaMet, startAt, endAt]);

  const handleNext = async (values: ValidSPIVFormValues, bag: SPIVFormBag) => {
    showLoading();
    const {id, claimVendorCode, departmentNumber, description, endAt, locationCriteria, startAt} =
      values;
    const requestData: EditSPIVRequest | CreateSPIVRequest = {
      claimVendorCode,
      departmentNumber,
      description,
      endAt: new Date(endAt).toISOString(),
      locationCriteria,
      startAt: new Date(startAt).toISOString(),
    };
    if (id) {
      try {
        if (!disableUpdateOnNextStepOne) {
          const response: AxiosResponse<SPIVResponse> = await put(
            api(Consts.Api.Spiv.replace(':id', `${values.id}`)),
            requestData
          );
          const updatedValues = getUpdatedFormValues(response.data);
          updateFormValues(updatedValues);
          onNext({...values, ...updatedValues}, bag);
        } else {
          onNext(values, bag);
        }
        alertService.clear(defaultAlertId);
      } catch (error: any) {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      } finally {
        hideLoading();
      }
    } else {
      try {
        const response: AxiosResponse<SPIVResponse> = await post(
          api(Consts.Api.Spivs),
          requestData
        );
        const updatedValues = getUpdatedFormValues(response.data);
        updateFormValues(updatedValues);
        onNext({...values, ...updatedValues}, bag);
        alertService.clear(defaultAlertId);
      } catch (error: any) {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      } finally {
        hideLoading();
      }
    }
  };

  const onConfirmClaimVendorChange = useCallback(async () => {
    if (id) {
      try {
        showLoading();
        await del(api(Consts.Api.SpivValues.replace(':id', `${id}`)));
        setFieldValue('spivValues', []);
        setFieldValue('spivValueCount', 0);
      } catch (error: any) {
        alertService.alert({
          id: defaultAlertId,
          ...{message: 'Failed to clear spiv values', response: error.response},
        });
      } finally {
        hideLoading();
      }
    }
  }, [hideLoading, showLoading, id, setFieldValue]);

  const defaultDepartment = departments?.find((x) => x.number === departmentNumber) ?? null;

  return (
    <Root>
      <FormStep step={step} title={title}>
        <ActiveClaimWarning
          entityActionType={EntityActionType.Deal}
          hasActiveClaim={hasActiveClaim}
          hasRaisedClaim={hasRaisedClaim}
        />
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="isNarta" fullWidth>
            Is this SPIV being processed through NARTA?
          </FieldLabel>
          <SPIVNartaSwitch
            disabled={disabledFields.nartaSwitch}
            id="isNarta"
            confirm={spivValueCount > 0 && id ? SPIVClaimVendorChangeConfirmation : undefined}
            onConfirmChange={onConfirmClaimVendorChange}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="claimVendor">Claim Vendor</FieldLabel>
          <SPIVClaimVendorSearch
            id="claimVendorCode"
            disabled={disabledFields.claimVendor || claimVendorCode === Consts.Narta.Code}
            fullWidth
            confirm={spivValueCount > 0 && id ? SPIVClaimVendorChangeConfirmation : null}
            onConfirmChange={onConfirmClaimVendorChange}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="description">SPIV Description</FieldLabel>
          <InputField
            id="description"
            name="description"
            fullWidth
            placeholder="Add your description here"
            disabled={disabledFields.description}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="departmentNumber">Department</FieldLabel>
          <SelectField
            loading={departmentLoading}
            options={departments ?? []}
            defaultOption={defaultDepartment}
            labelFunc={(x) => x.description}
            valueFunc={(x) => x.number}
            id="departmentNumber"
            name="departmentNumber"
            placeholder="Start typing a department"
            disabled={disabledFields.departmentNumber}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FieldLabel htmlFor="startAt" fullWidth>
                Start Date
              </FieldLabel>
              <DatePickerField
                dateType={DateType.RangeStart}
                id="startAt"
                name="startAt"
                placeholder="Type or choose a start date"
                fullWidth
                disabled={disabledFields.startAt}
                maxDate={noFutureStartCriteriaMet ? new Date().toISOString() : undefined}
                validationSchema={
                  noFutureStartCriteriaMet ? noFutureStartAtValidationSchema() : undefined
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FieldLabel htmlFor="endAt" fullWidth>
                End Date
              </FieldLabel>
              <DatePickerField
                dateType={DateType.RangeEnd}
                id="endAt"
                name="endAt"
                placeholder="Type or choose an end date"
                maxDate={Consts.Date.MaxEndDateMonthsFromNow}
                fullWidth
                disabled={disabledFields.endAt}
              />
            </Grid>
          </Grid>
          {showNoFutureStartError ? (
            <Grid item xl={12}>
              <ErrorBox type={AlertType.Error} data-testid="no-future-start-error">
                <div>
                  Start date in the future cannot be selected after a SPIV has started, please pick
                  a date to continue. If this SPIV needs to end, please delete
                </div>
              </ErrorBox>
            </Grid>
          ) : null}
          {!showNoFutureStartError && showNoFutureStartInfo ? (
            <Grid item xl={12}>
              <ErrorBox type={AlertType.Info} data-testid="no-future-start-info">
                <div>
                  Start date in the future cannot be selected after a SPIV has started, please pick
                  a date to continue. If this SPIV needs to end, please delete
                </div>
              </ErrorBox>
            </Grid>
          ) : null}
          {showStartDateDaysAfterWarning ||
          showStartDateDaysBeforeWarning ||
          showDateRangeWarning ? (
            <Grid item xl={12}>
              <ErrorBox type={AlertType.Warning}>
                {showStartDateDaysBeforeWarning ? (
                  <div>
                    The start date is equal to or more than{' '}
                    {config.dealStartDateWarningDaysBeforeToday} days before today.
                  </div>
                ) : null}
                {showStartDateDaysAfterWarning ? (
                  <div>
                    The start date is equal to or more than{' '}
                    {config.dealStartDateWarningDaysBeforeToday} days after today.
                  </div>
                ) : null}
                {showDateRangeWarning ? (
                  <div>
                    The difference between start date and end date is greater than{' '}
                    {config.dealDateRangeWarningDaysBetween} days.
                  </div>
                ) : null}
              </ErrorBox>
            </Grid>
          ) : null}
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel>Location Assignment</FieldLabel>
          <LocationField
            id="locationCriteria"
            name="locationCriteria"
            disabled={disabledFields.locationCriteria}
          />
        </FieldGrid>
      </FormStep>
      <StepperFormActionSection
        handleBack={onBack}
        handleNext={handleNext}
        step={step}
        totalStep={totalStep}
      />
    </Root>
  );
};

export default StepSPIVDetails;
