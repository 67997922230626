import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import Consts from '../../app/Consts';
import {hasRole} from '../../app/Permission';
import {useAppSelector} from '../../app/store';
import {selectProfileRoles} from '../../app/selectors';
import {useFeatureFlag} from '../../utils/featureFlags';
import {CreateDealIcon, CreateContractAgreementIcon, CreateAdjustmentIcon} from '../Icons';
import {Modal} from '../Modal';
import SelectionCard from './SelectionCard';

const StyledAdjustmentIcon = styled(CreateAdjustmentIcon)`
  width: 93px !important;
  height: 80px;
`;

type Props = {
  open: boolean;
  onClose: () => void;
  onSelectDealOption: () => void;
};

const AddNewModal: FC<Props> = ({open, onClose, onSelectDealOption}) => {
  const roles = useAppSelector(selectProfileRoles);
  const navigate = useNavigate();
  const spivsFeatureEnabled = useFeatureFlag(Consts.FeatureFlags.EnableSpivs)?.enabled;
  const mnmFeatureEnabled = useFeatureFlag(Consts.FeatureFlags.EnableMixAndMatch)?.enabled;

  const canCreateDeal = hasRole(Consts.UserRoleEnum.AddOrUpdateDeals, roles);
  const canCreateContractAgreement = hasRole(
    Consts.UserRoleEnum.AddOrUpdateContractAgreements,
    roles
  );
  const canCreateAdjustment = hasRole(Consts.UserRoleEnum.ApproveDealsAndContractAgreements, roles);

  const handleSelectDealOption = () => {
    if (canCreateDeal) {
      if (!spivsFeatureEnabled && !mnmFeatureEnabled) {
        navigate(Consts.RouterPath.CreateDeal);
      } else {
        onSelectDealOption();
      }
      onClose();
    }
  };

  const handleSelectAgreementOption = () => {
    if (canCreateContractAgreement) {
      navigate(Consts.RouterPath.CreateContractAgreement);
      onClose();
    }
  };

  const handleSelectAdjustmentOption = () => {
    if (canCreateAdjustment) {
      navigate(Consts.RouterPath.CreateAdjustment);
      onClose();
    }
  };

  return (
    <Modal open={open} title="What would you like to add?" onClose={onClose}>
      <SelectionCard
        disabled={!canCreateDeal}
        icon={CreateDealIcon}
        onClick={handleSelectDealOption}
        title="Deal"
      >
        Short-term promotions or deals agreed to with suppliers on an ad-hoc basis. Can include
        scanbacks, advertising costs, etc.
      </SelectionCard>
      <SelectionCard
        disabled={!canCreateContractAgreement}
        icon={CreateContractAgreementIcon}
        onClick={handleSelectAgreementOption}
        title="Contract Agreement"
      >
        Long-term agreements with suppliers, which group multiple rebates that are claimed at
        regular intervals (e.g. monthly or quarterly.)
      </SelectionCard>
      <SelectionCard
        disabled={!canCreateAdjustment}
        icon={StyledAdjustmentIcon}
        onClick={handleSelectAdjustmentOption}
        title="Adjustments"
      >
        Offsets to purchases made for a time period which will feed into rebate and profitability
        calculations
      </SelectionCard>
    </Modal>
  );
};

export default AddNewModal;
