import React, {useState} from 'react';
import {ConfirmModal} from '.';
import ErrorSection, {ErrorList, ErrorListItem} from './ErrorSection';
import {WarnIcon} from '../Icons';
import Consts from '../../app/Consts';

const styles = {
  okButton: {
    width: '340px',
  },
  cancelButton: {
    width: '340px',
  },
};

const BulkUploadWarningModal = ({open, response, onClose, onReupload}) => {
  const [currentErrror, setCurrentError] = useState(Consts.ProductBulkUploadErrorType.None);
  const warning = (response && response.data && response.data.warning) || {};
  const renderSubtitle = () => {
    if (warning.lineWarnings) {
      return <div>Please review the below information and choose how to proceed.</div>;
    }
  };

  const renderLineWarnings = (warning) => {
    if (warning && warning.lineWarnings && warning.lineWarnings.length > 0) {
      let lineWarnings = warning.lineWarnings;
      let count = lineWarnings.length;
      let expanded = currentErrror === Consts.ProductBulkUploadErrorType.LineWarnings;
      return (
        <div>
          <ErrorSection
            title={`${count} ${count > 1 ? 'lines have warnings' : 'line has warnings'}`}
            toggleButtonText="View Warnings"
            expanded={expanded}
            onToggle={() => {
              setCurrentError(
                expanded
                  ? Consts.ProductBulkUploadErrorType.None
                  : Consts.ProductBulkUploadErrorType.LineWarnings
              );
            }}
            sectionIcon={WarnIcon}
          >
            {expanded && (
              <ErrorList>
                {lineWarnings.map((lineError) => {
                  let message = lineError.warningMessages.join(', ');
                  return (
                    <ErrorListItem key={lineError.line}>
                      Line {lineError.line} - {message}
                    </ErrorListItem>
                  );
                })}
              </ErrorList>
            )}
          </ErrorSection>
        </div>
      );
    }
  };

  return (
    <ConfirmModal
      title={'Upload Information'}
      open={open}
      onOk={onClose}
      onCancel={onReupload}
      okText="Continue with current upload"
      cancelText="Upload Again"
      renderSubtitle={renderSubtitle}
      styles={styles}
    >
      {renderLineWarnings(warning)}
    </ConfirmModal>
  );
};

export default BulkUploadWarningModal;
