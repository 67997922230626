import React, {FC, useState, useEffect, useCallback, useContext} from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import {useFormikContext} from 'formik';
import * as R from 'ramda';
import {AxiosResponse} from 'axios';
import config from '../../app/Config';
import {alertService, defaultAlertId} from '../../app/AlertService';
import Consts from '../../app/Consts';
import LoadingContext from '../../app/LoadingContext';
import {get, api, del, post} from '../../utils/Request';
import {
  Pagination,
  DealValue,
  DealAgreementDealValuesResponse,
  DealAgreementDealValueDeleteResponse,
  DealFormValues,
  DealFormBag,
  EntityActionType,
} from '../../types';
import {ErrorBox} from '../Alert';
import {LinkButton, StaticButton} from '../Button';
import {CustomAutoComplete} from '../CustomAutoComplete';
import RaisedClaimWarning from '../Claim/RaisedClaimWarning';
import ActiveClaimWarning from '../Claim/ActiveClaimWarning';
import FieldGrid from '../Form/FieldGrid';
import FieldLabel from '../Form/FieldLabel';
import RadioGroupField from '../Form/RadioGroupField';
import FormStep from '../Form/FormStep';
import CustomRadioGroup from '../Form/RadioGroup';
import StepperFormActionSection from '../Form/StepperFormActionSection';
import DownloadTemplatesModal from '../Modal/DownloadTemplatesModal';
import ClearDealValueDataConfirmModal from '../Modal/ClearDealValueDataConfirmModal';
import UnSavedChangesConfirmModal from '../Modal/UnSavedChangesConfirmModal';
import {BulkUploadModal, FinanceAccountClearModal, BulkUploadConfirmModal} from '../Modal';
import DealValuesTable from './DealValuesTable';

const ClearDataConfirmReason = {
  BulkUpload: 'BulkUpload',
  DealTypeChange: 'DealTypeChange',
  ToggleProductSelector: 'TogleProductSelector',
  None: 'None',
};

const DealTypeFieldGrid = styled(FieldGrid)`
  background-color: #f7f9fa;
  border: 1px solid #dedede;
  padding: 2.5rem 2.1875rem;
  margin-bottom: 2.5rem;
`;

type Props = {
  step: number;
  title: string;
  validationSchema: any;
  totalStep: number;
  handleBack: (values: DealFormValues, bag: DealFormBag) => void;
  handleNext: (values: DealFormValues, bag: DealFormBag) => void;
  style?: React.CSSProperties;
};

const StepDealValues: FC<Props> = ({
  step,
  title,
  validationSchema,
  totalStep,
  handleBack,
  handleNext,
}) => {
  const [templatesModalOpen, setTemplatesModalOpen] = useState(false);
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [financeAccountClearedModalOpen, setFinanceAccountClearedModalOpen] = useState(false);
  const [clearDataWarningModalOpen, setClearDataWarningModalOpen] = useState(false);
  const [bulkUploadConfirmModalOpen, setBulkUploadConfirmModalOpen] = useState(false);
  const [clearExistingDuringBulkUpload, setClearExistingDuringBulkUpload] = useState(false);
  const [unSavedChangesConfirmModalOpen, setUnSavedChangesConfirmModalOpen] = useState(false);
  const [clearDataConfirmReason, setClearDataConfirmReason] = useState(ClearDataConfirmReason.None);
  const [skuSelectorMode, setSkuSelectorMode] = useState(true);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);
  const [searchTextForSupplier, setSearchTextForSupplier] = useState('');
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [isEditingRow, setIsEditingRow] = useState(false);
  const [pagination, setPagination] = useState(Consts.DefaultPagination);
  const bag = useFormikContext<DealFormValues>();
  const {
    values: {
      id,
      dealType,
      dealValues,
      suppliers,
      claimVendor,
      claimVendorSuppliers,
      hasRaisedClaim,
      hasActiveClaim,
      isClaimVendorGstFree,
      isFinalClaimRaised,
    },
    setFieldValue,
    getFieldMeta,
  } = bag;

  const {showLoading, hideLoading} = useContext(LoadingContext);

  useEffect(() => {
    setSkuSelectorMode(dealType !== Consts.StandardDealTypeEnum.FixedAmount);
  }, [dealType]);

  const loadDealValues = useCallback(
    (dealId: number | undefined, paging?: Partial<Pagination>, setSuppliers?: boolean) => {
      if (!dealId) {
        return;
      }
      showLoading();
      get(api(Consts.Api.DealAgreementValues.replace(':id', `${dealId}`)), {params: paging || {}})
        .then((response: AxiosResponse<DealAgreementDealValuesResponse>) => {
          const responseData = response.data;
          setFieldValue('dealValues', responseData.data || []);
          if (setSuppliers) {
            setFieldValue('suppliers', responseData.suppliers);
          }
          setFieldValue('isClaimVendorGstFree', responseData.isClaimVendorGstFree);
          if (responseData.data) {
            const isSkuSelectorMode = responseData.data.every((value: DealValue) => {
              return !!value.productCode;
            });
            setFieldValue('dealType', responseData.dealType);
            setSkuSelectorMode(isSkuSelectorMode);
            setFieldValue('dealValueCount', responseData.totalCount);
            setPagination({
              pageSize: response.data.pageSize,
              currentPage: response.data.currentPage,
              totalCount: response.data.totalCount,
              totalPages: response.data.totalPages,
            });
          } else {
            setPagination(Consts.DefaultPagination);
            setFieldValue('dealValueCount', 0);
          }

          alertService.clear(defaultAlertId);
        })
        .catch((error) => {
          alertService.alert({
            id: defaultAlertId,
            ...{message: 'Failed to load deal values', response: error.response},
          });
        })
        .finally(() => {
          hideLoading();
        });
    },
    [setFieldValue, showLoading, hideLoading]
  );

  const clearDealValues = useCallback(
    (dealId: number | undefined, dealType?: string | null, switchSelectorMode?: boolean) => {
      if (!dealId) {
        return;
      }
      showLoading();
      del(api(Consts.Api.DealAgreementValues.replace(':id', `${dealId}`)))
        .then((response: AxiosResponse<DealAgreementDealValueDeleteResponse>) => {
          setFieldValue('dealValues', []);
          setFieldValue('dealValueCount', 0);
          if (
            response.data.financeAccountCleared &&
            dealType !== Consts.StandardDealTypeEnum.PriceProtection
          ) {
            setFinanceAccountClearedModalOpen(true);
            setFieldValue('financeAccountCleared', true);
          }
          if (switchSelectorMode) {
            setSkuSelectorMode((skuSelectorMode) => !skuSelectorMode);
          }
          setPagination(Consts.DefaultPagination);
        })
        .catch((error) => {
          alertService.alert({
            id: defaultAlertId,
            ...{message: 'Failed to clear deal values', response: error.response},
          });
        })
        .finally(() => {
          hideLoading();
        });
    },
    [showLoading, hideLoading, setFieldValue, setSkuSelectorMode]
  );

  useEffect(() => {
    if (id) {
      loadDealValues(id, Consts.DefaultPagination, true);
    }
  }, [id, loadDealValues]);

  useEffect(() => {
    setLoadingSuppliers(true);
    get(api(Consts.Api.Suppliers), {
      params: {
        searchText: searchTextForSupplier,
        entityCode: config.entityCode,
      },
    })
      .then((response) => {
        setLoadingSuppliers(false);
        setSupplierOptions(response.data.data);
      })
      .catch((error) => {
        setLoadingSuppliers(false);
        alertService.alert({
          id: defaultAlertId,
          ...{message: 'Failed to load suppliers', response: error.response},
        });
      });
  }, [searchTextForSupplier]);

  function onUploadComplete(response?: any, keepModalOpen?: boolean) {
    setBulkUploadModalOpen(Boolean(keepModalOpen));
    loadDealValues(id);
    if (
      response?.data?.financeAccountCleared &&
      dealType !== Consts.StandardDealTypeEnum.PriceProtection
    ) {
      setFinanceAccountClearedModalOpen(true);
      setFieldValue('financeAccountCleared', true);
    }
  }
  function postConfirmClearData(reason: string, dealType: string) {
    reason = reason || clearDataConfirmReason;
    switch (reason) {
      case ClearDataConfirmReason.BulkUpload:
        setBulkUploadModalOpen(true);
        break;
      case ClearDataConfirmReason.DealTypeChange:
        clearDealValues(id, dealType);
        break;
      case ClearDataConfirmReason.ToggleProductSelector:
        clearDealValues(id, dealType, false);
        setFieldValue('suppliers', []);
        break;
      default:
        setFieldValue('dealValues', []);
        setFieldValue('dealValueCount', 0);
        setPagination(Consts.DefaultPagination);
    }
  }
  function onConfirmClearData() {
    setClearDataWarningModalOpen(false);
    postConfirmClearData(clearDataConfirmReason, dealType ?? '');
  }
  function onConfirmIgnoreChangesAndContinue() {
    handleNext(bag.values, bag);
  }
  async function goToNext(values: DealFormValues, bag: DealFormBag) {
    if (isEditingRow) {
      setUnSavedChangesConfirmModalOpen(true);
    } else {
      if (!id) {
        return;
      }
      showLoading();
      post(api(Consts.Api.DealAgreementSuppliers.replace(':id', `${id}`)), {
        suppliers: suppliers.map((x) => x.number),
      })
        .then(() => {
          handleNext(values, bag);
        })
        .catch((error) => {
          alertService.alert({
            id: defaultAlertId,
            ...{message: error.message, response: error.response},
          });
        })
        .finally(() => {
          hideLoading();
        });
    }
  }
  function onCancelClearData() {
    setClearDataWarningModalOpen(false);
  }
  function onBulkUpload() {
    if (dealValues.length > 0) {
      setBulkUploadConfirmModalOpen(true);
      setBulkUploadModalOpen(false);
    } else {
      setBulkUploadModalOpen(true);
    }
  }
  function renderHeader() {
    return (
      <>
        <LinkButton
          type="button"
          onClick={() => {
            setTemplatesModalOpen(true);
          }}
        >
          Download Excel Templates
        </LinkButton>
        <DownloadTemplatesModal
          title={'Download Excel Templates'}
          fileNames={[
            'Sales Template (Deals).xlsx',
            'Purchases Template (Deals).xlsx',
            'Fixed Dollar Template (Deals).xlsx',
          ]}
          open={templatesModalOpen}
          onClose={() => {
            setTemplatesModalOpen(false);
          }}
        />
        {!!id ? (
          <BulkUploadModal
            title={'Bulk Upload Deal Values'}
            uploadUrl={api(
              Consts.Api.DealAgreementValues.replace(':id', `${id}`) +
                `?clearExisting=${clearExistingDuringBulkUpload}`
            )}
            open={bulkUploadModalOpen}
            onClose={() => {
              setBulkUploadModalOpen(false);
            }}
            onOpenTemplate={() => {
              setBulkUploadModalOpen(false);
              setTemplatesModalOpen(true);
            }}
            onComplete={onUploadComplete}
            onReupload={() => clearDealValues(id, dealType)}
          />
        ) : null}
        <ClearDealValueDataConfirmModal
          open={clearDataWarningModalOpen}
          onOk={onConfirmClearData}
          onCancel={onCancelClearData}
        />
        <BulkUploadConfirmModal
          open={bulkUploadConfirmModalOpen}
          onKeepAdding={() => {
            setClearExistingDuringBulkUpload(false);
            setBulkUploadConfirmModalOpen(false);
            setBulkUploadModalOpen(true);
          }}
          onReplace={() => {
            setClearExistingDuringBulkUpload(true);
            setBulkUploadConfirmModalOpen(false);
            setBulkUploadModalOpen(true);
          }}
          onCancel={() => {
            setBulkUploadConfirmModalOpen(false);
          }}
        />
        <FinanceAccountClearModal
          open={financeAccountClearedModalOpen}
          onClose={() => {
            setFinanceAccountClearedModalOpen(false);
          }}
        />
      </>
    );
  }

  function onTableDataUpdating(isUpdating: boolean) {
    setIsEditingRow(isUpdating);
  }
  const dealValuesMeta = getFieldMeta('dealValues');
  return (
    <>
      <FormStep step={step} title={title} renderHeader={renderHeader}>
        <ActiveClaimWarning
          hasActiveClaim={hasActiveClaim}
          hasRaisedClaim={hasRaisedClaim}
          entityActionType={EntityActionType.Deal}
        />
        {hasRaisedClaim &&
        (dealType === Consts.StandardDealTypeEnum.FixedAmount ||
          dealType === Consts.StandardDealTypeEnum.PriceProtection) ? (
          <RaisedClaimWarning />
        ) : null}
        <DealTypeFieldGrid item xs={12}>
          <FieldLabel sx={{fontSize: '20px', marginBotom: '15px'}} fullWidth>
            This deal is going to be calculated based on...
          </FieldLabel>
          <RadioGroupField
            name="dealType"
            value={dealType}
            options={Consts.StandardDealTypes}
            confirm={dealValues.length > 0 ? ClearDealValueDataConfirmModal : null}
            onConfirm={(newDealTypeValue) => {
              setClearDataConfirmReason(ClearDataConfirmReason.DealTypeChange);
              postConfirmClearData(
                ClearDataConfirmReason.DealTypeChange,
                newDealTypeValue.toString()
              );
            }}
            disabled={isFinalClaimRaised}
          />
        </DealTypeFieldGrid>
        {dealType ? (
          <FieldGrid item xs={12}>
            <FieldLabel>SKUs are included based on...</FieldLabel>
            <CustomRadioGroup
              disabled={
                dealType === Consts.StandardDealTypeEnum.PriceProtection || isFinalClaimRaised
              }
              name="skuSelectorMode"
              options={[
                {label: 'Individual SKU selector', value: true},
                {label: 'Product selection tool', value: false},
              ]}
              value={skuSelectorMode}
              confirm={dealValues.length > 0 ? ClearDealValueDataConfirmModal : null}
              onChanged={(mode) => {
                setSkuSelectorMode(Boolean(mode));
                setClearDataConfirmReason(ClearDataConfirmReason.ToggleProductSelector);
                postConfirmClearData(ClearDataConfirmReason.ToggleProductSelector, dealType);
              }}
            />
          </FieldGrid>
        ) : null}
        {dealType && skuSelectorMode ? (
          <FieldGrid item xs={12}>
            <Grid container alignItems="baseline">
              <FieldLabel fullWidth>Supplier(s)</FieldLabel>
              <StaticButton
                onClick={() => {
                  setFieldValue('suppliers', claimVendorSuppliers);
                }}
                disabled={isFinalClaimRaised}
              >
                Fill in with suppliers of {claimVendor.name} ({claimVendorSuppliers.length})
              </StaticButton>
            </Grid>
            <CustomAutoComplete
              loading={loadingSuppliers}
              options={R.union(supplierOptions, suppliers)}
              value={suppliers}
              disableCloseOnSelect
              onInputBlur={() => {
                setSearchTextForSupplier('');
              }}
              multiple
              limitTags={8}
              getOptionLabel={(option) => option.name}
              onInputChange={(event, newInputValue, reason) => {
                if (reason !== 'reset') {
                  setSearchTextForSupplier(newInputValue);
                }
              }}
              inputValue={searchTextForSupplier}
              onChange={(event, newValue) => {
                setFieldValue('suppliers', [...newValue]);
              }}
              placeholder="Search suppliers"
              getLimitTagsText={(more) => `+ ${more} suppliers`}
              disabled={isFinalClaimRaised}
            />
          </FieldGrid>
        ) : null}
        {dealType ? (
          <FieldGrid item xs={12}>
            <DealValuesTable
              skuSelectorMode={skuSelectorMode}
              pagination={pagination}
              isClaimVendorGstFree={isClaimVendorGstFree}
              onPagination={(currentPage, pageSize) => {
                loadDealValues(id, {currentPage, pageSize});
              }}
              onTableDataUpdating={onTableDataUpdating}
              onBulkUpload={onBulkUpload}
            />
          </FieldGrid>
        ) : null}

        {!!dealValuesMeta.error && !!dealValuesMeta.touched ? (
          <FieldGrid item xs={12}>
            <ErrorBox>{dealValuesMeta.error}</ErrorBox>
          </FieldGrid>
        ) : null}
      </FormStep>
      <UnSavedChangesConfirmModal
        open={unSavedChangesConfirmModalOpen}
        onOk={onConfirmIgnoreChangesAndContinue}
        onCancel={() => {
          setUnSavedChangesConfirmModalOpen(false);
        }}
      />
      <StepperFormActionSection
        handleBack={handleBack}
        handleNext={goToNext}
        step={step}
        totalStep={totalStep}
      />
    </>
  );
};

export default StepDealValues;
