import React, {FC} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import Consts from '../../app/Consts';
import {useFeatureFlag} from '../../utils/featureFlags';
import {CreateDealIcon, MixNMatchIcon, SpivsIcon} from '../Icons';
import {Modal} from '../Modal';
import SelectionCard from './SelectionCard';

type Props = {
  open: boolean;
  onClose: () => void;
};

const NewDealTypeModal: FC<Props> = ({open, onClose}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const spivsFeatureEnabled = useFeatureFlag(Consts.FeatureFlags.EnableSpivs)?.enabled;
  const mnmFeatureEnabled = useFeatureFlag(Consts.FeatureFlags.EnableMixAndMatch)?.enabled;

  // edge case where a user is already on the create form page and wants to create another one
  // most direct path to resetting existing form values is reloading the page
  const handleSelectDealOption = () => {
    if (location.pathname === Consts.RouterPath.CreateDeal) {
      window.location.reload?.();
    } else {
      navigate(Consts.RouterPath.CreateDeal);
    }
    onClose();
  };

  const handleMNMOption = () => {
    if (mnmFeatureEnabled) {
      if (location.pathname === Consts.RouterPath.CreateMixAndMatch) {
        window.location.reload?.();
      } else {
        navigate(Consts.RouterPath.CreateMixAndMatch);
      }
      onClose();
    }
  };

  const handleSpivsOption = () => {
    if (spivsFeatureEnabled) {
      if (location.pathname === Consts.RouterPath.CreateSPIV) {
        window.location.reload?.();
      } else {
        navigate(Consts.RouterPath.CreateSPIV);
      }
      onClose();
    }
  };

  return (
    <Modal open={open} title="Select deal type" onClose={onClose}>
      <SelectionCard
        icon={CreateDealIcon}
        title="Sales, Purchases and Fixed Amounts"
        onClick={handleSelectDealOption}
      >
        Short-term promotions or deals agreed to with suppliers on an ad-hoc basis. Can include
        scanbacks, advertising costs, etc.
      </SelectionCard>
      {spivsFeatureEnabled ? (
        <SelectionCard icon={SpivsIcon} title="SPIVs" onClick={handleSpivsOption}>
          Short-term sales performance incentives paid to staff to boost sales on a product.
        </SelectionCard>
      ) : null}
      {mnmFeatureEnabled ? (
        <SelectionCard icon={MixNMatchIcon} title="Mix & Match" onClick={handleMNMOption}>
          Deals that provide a benefit to customers when a specific combination of products are
          purchased.
        </SelectionCard>
      ) : null}
    </Modal>
  );
};

export default NewDealTypeModal;
