import React, {FC, useCallback, useContext, useState, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {AxiosResponse} from 'axios';
import Box from '@mui/material/Box';
import LoadingContext from '../../../app/LoadingContext';
import Consts from '../../../app/Consts';
import {useAppSelector} from '../../../app/store';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import {
  SPIVSummaryResponse,
  SPIVResponse,
  Config,
  EntityActionType,
  ClaimListResponse,
  ClaimListItemViewModel,
} from '../../../types';
import {api, post, del, get, put} from '../../../utils/Request';
import {isValidDate} from '../../../utils/DateUtils';
import {HeadingComponent} from '../../../components/HeadingComponent';
import {DeleteAgreementOrRebateConfirmModal} from '../../../components/Modal';
import EditAgreementOrRebateConfirmModal from '../../../components/Modal/EditAgreementOrRebateConfirmModal';
import {SpivsIcon, EditIcon, DeleteIcon, DuplicateIcon} from '../../../components/Icons';

type HeadingButton = {
  label: string;
  icon: React.ReactNode;
  style: React.CSSProperties;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  role: string;
  disabled?: boolean;
  disabledText?: string;
  isLocked?: boolean;
};

type Props = {
  spivData: SPIVSummaryResponse;
  onCancel: (spivSummary: SPIVSummaryResponse) => void;
};

export const isSPIVLocked = (configs: Config | undefined, endAt: string): boolean => {
  if (!configs) {
    return false;
  }
  const lockDate = new Date(configs[Consts.ConfigNameEnum.DealLockDate as keyof typeof configs]);
  const cutoffDate = new Date(
    configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs]
  );
  if (isValidDate(lockDate) && isValidDate(cutoffDate)) {
    return new Date() >= lockDate && new Date(endAt) < cutoffDate;
  }
  return false;
};

export const shouldConfirmAction = (claimsResponseData: ClaimListResponse['data']): boolean =>
  claimsResponseData.some((claim: ClaimListItemViewModel) =>
    [
      Consts.ClaimStatusEnum.Raised,
      Consts.ClaimStatusEnum.Invoiced,
      Consts.ClaimStatusEnum.Finalised,
      Consts.ClaimStatusEnum.SentToSupplier,
    ].includes(claim.status)
  );

const SPIVSummaryHeading: FC<Props> = ({spivData, onCancel}) => {
  const configs = useAppSelector((state) => state.configs.data);
  const navigate = useNavigate();
  const {showLoading, hideLoading} = useContext(LoadingContext);

  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
  const [openCancelConfirmModal, setOpenCancelConfirmModal] = useState(false);
  const [raisedClaimsResponse, setRaisedClaimsResponse] = useState<ClaimListResponse | null>(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [hasClaimsConfirmModal, setHasClaimsConfirmModal] = useState(false);
  const [ctrlKey, setCtrlKey] = useState(false);

  const isCancelled = spivData.status === Consts.AgreementStatusEnum.Cancelled;

  const editSPIV = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const routerPath = Consts.RouterPath.EditSpiv.replace(':id', `${spivData.id}`);
      if (event.ctrlKey) {
        window.open(routerPath, '_blank');
      } else {
        navigate(routerPath);
      }
    },
    [navigate, spivData]
  );

  const deleteSPIV = async () => {
    try {
      showLoading();
      await del(api(Consts.Api.Spiv.replace(':id', `${spivData.id}`)));
      navigate(Consts.RouterPath.MyDeals);
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const handleCancel = useCallback(async () => {
    try {
      showLoading();
      const response: AxiosResponse<ClaimListResponse> = await get(
        api(Consts.Api.DealAgreementClaims.replace(':id', `${spivData.id}`))
      );
      const claims = response.data?.data;
      if (!claims || claims.length === 0) {
        setOpenCancelConfirmModal(true);
      } else if (!shouldConfirmAction(claims)) {
        setRaisedClaimsResponse(response.data);
        setHasClaimsConfirmModal(true);
      }
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  }, [hideLoading, showLoading, spivData]);

  const cancelSPIV = useCallback(async () => {
    try {
      showLoading();
      const spivSummaryResponse = await put(
        api(Consts.Api.SpivCancel.replace(':id', `${spivData.id}`))
      );
      onCancel(spivSummaryResponse.data);
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  }, [hideLoading, showLoading, spivData, onCancel]);

  const handleNavAction = useCallback(
    async (type: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.persist();
      setCtrlKey(event.ctrlKey);
      const {isDeletable, isCancellable} = spivData;
      switch (type) {
        case 'duplicate': {
          try {
            showLoading();
            const response: AxiosResponse<SPIVResponse> = await post(
              api(Consts.Api.SpivDuplicate.replace(':id', `${spivData.id}`))
            );
            navigate(Consts.RouterPath.EditSpiv.replace(':id', `${response.data.id}`));
          } catch (error: any) {
            alertService.alert({
              ...{message: error.message, response: error.response},
              id: defaultAlertId,
            });
          } finally {
            hideLoading();
          }
          break;
        }
        case 'edit': {
          try {
            showLoading();
            const response: AxiosResponse<ClaimListResponse> = await get(
              api(Consts.Api.DealAgreementClaims.replace(':id', `${spivData.id}`))
            );
            const claims = response.data?.data;
            if (claims && shouldConfirmAction(claims)) {
              setRaisedClaimsResponse(response.data);
              setOpenEditModal(true);
            } else {
              editSPIV(event);
            }
          } catch (error: any) {
            alertService.alert({
              ...{message: error.message, response: error.response},
              id: defaultAlertId,
            });
          } finally {
            hideLoading();
          }
          break;
        }
        case 'delete': {
          if (!isDeletable && isCancellable) {
            handleCancel();
          } else if (isDeletable) {
            setOpenDeleteConfirmModal(true);
          }
          break;
        }
        default:
          break;
      }
    },
    [editSPIV, hideLoading, navigate, spivData, showLoading, handleCancel]
  );

  const headingButtons: HeadingButton[] = useMemo(() => {
    if (!spivData) {
      return [];
    }
    const buttons: HeadingButton[] = [
      {
        label: 'Duplicate SPIV',
        icon: <DuplicateIcon style={{width: '1.3125rem'}} />,
        style: {color: '#626262', fontWeight: 400},
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          handleNavAction('duplicate', event),
        role: Consts.UserRoleEnum.AddOrUpdateDeals,
      },
    ];
    if (!spivData.isDeleted && !isCancelled) {
      buttons.push(
        {
          label: 'Edit SPIV',
          icon: <EditIcon style={{width: '1.3125rem'}} />,
          style: {color: '#626262', fontWeight: 400},
          onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            handleNavAction('edit', event),
          role: Consts.UserRoleEnum.AddOrUpdateDeals,
          isLocked: isSPIVLocked(configs, spivData?.endAt),
        },
        {
          label: 'Delete SPIV',
          icon: <DeleteIcon style={{width: '1.3125rem'}} />,
          style: {color: '#d0021b', fontWeight: 400},
          onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            handleNavAction('delete', event),
          role: Consts.UserRoleEnum.DeleteDeals,
          isLocked: isSPIVLocked(configs, spivData.endAt),
          disabled: !spivData.isDeletable && !spivData.isCancellable,
          disabledText: 'Cannot be deleted as there are related invoiced and/or raised claim(s).',
        }
      );
    }
    return buttons;
  }, [spivData, handleNavAction, configs, isCancelled]);

  return (
    <>
      {isCancelled ? (
        <Box
          sx={{
            width: 'calc(100% + 3rem)',
            height: '3rem',
            backgroundColor: '#E02020',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#FFFFFF',
            fontWeight: 700,
            fontSize: '1.25rem',
            marginLeft: '-1.5rem',
          }}
          data-testid="cancelled-banner"
        >
          Cancelled
        </Box>
      ) : (
        ''
      )}
      <HeadingComponent
        headingIcon={<SpivsIcon />}
        headingLabel="SPIV"
        buttons={headingButtons}
        style={spivData.isDeleted || isCancelled ? {paddingTop: '0px'} : {}}
      />
      <DeleteAgreementOrRebateConfirmModal
        entityActionType={EntityActionType.SPIV}
        open={openDeleteConfirmModal}
        onCancel={() => setOpenDeleteConfirmModal(false)}
        onOk={() => {
          setOpenDeleteConfirmModal(false);
          deleteSPIV();
        }}
      />
      <DeleteAgreementOrRebateConfirmModal
        entityActionType={EntityActionType.SPIV}
        open={openCancelConfirmModal}
        onCancel={() => setOpenCancelConfirmModal(false)}
        onOk={() => {
          setOpenCancelConfirmModal(false);
          cancelSPIV();
        }}
        cancelText="Go back"
        okText="Confirm and cancel"
        children={
          <>
            This SPIV has already started and cannot be deleted. If you proceed it will be cancelled
            and no claims will be created,
            <strong> this cannot be reversed</strong>.
          </>
        }
      />
      <EditAgreementOrRebateConfirmModal
        entityActionType={EntityActionType.SPIV}
        referenceId={`${spivData.id}`}
        ctrlKey={ctrlKey}
        open={openEditModal}
        claimsResponse={raisedClaimsResponse}
        onOk={() => {
          setOpenEditModal(false);
        }}
        onCancel={() => setOpenEditModal(false)}
        modalTitle="Claim advanced past Ready to Process"
        modalSubtitle="There has been at least one claim advanced past Ready to Process against this SPIV, some fields will not be editable."
      />
      <EditAgreementOrRebateConfirmModal
        entityActionType={EntityActionType.SPIV}
        referenceId={`${spivData.id}`}
        ctrlKey={ctrlKey}
        open={hasClaimsConfirmModal}
        claimsResponse={raisedClaimsResponse}
        onOk={() => {
          setHasClaimsConfirmModal(false);
          cancelSPIV();
        }}
        onCancel={() => setHasClaimsConfirmModal(false)}
        okText="Confirm and cancel"
        modalTitle="Are you sure?"
        modalSubtitle="This SPIV has already started and cannot be deleted. If you proceed it will be cancelled.
        There is at least one claim that is not raised for this SPIV and it will be deleted. This cannot be reversed."
      />
    </>
  );
};

export default SPIVSummaryHeading;
