import React from 'react';
import {styled} from '@mui/material/styles';
import {setNestedObjectValues, useFormikContext} from 'formik';
import {BackButton, Button, OutlinedButton} from '../Button';

const PREFIX = 'StepperFormActionSection';

const classes = {
  actionSection: `${PREFIX}-actionSection`,
};

const Root = styled('div')(() => ({
  [`&.${classes.actionSection}`]: {
    marginRight: '31px',
    '& >button': {
      marginRight: '31px',
    },
  },
}));

const SaveDraftButton = styled(OutlinedButton)`
  height: 70px;
`;

const StepperFormActionSection = ({
  handleBack,
  handleNext,
  step,
  totalStep,
  lastStepButtonText = 'Finish',
  lastStepSaveDraftButtonText = 'Save Draft',
}) => {
  const bag = useFormikContext();
  const {setFieldValue, values, isSubmitting, validateForm, setTouched} = bag;
  const lastStep = step === totalStep;
  const buttonStyle =
    values.draftMode && lastStep
      ? {minWidth: '200px', paddingLeft: '50px', paddingRight: '50px'}
      : {};

  return (
    <Root className={classes.actionSection}>
      {step === 1 ? null : <BackButton onClick={() => handleBack(values, bag)}>Go Back</BackButton>}
      {lastStep && values.draftMode ? (
        <SaveDraftButton
          style={buttonStyle}
          disabled={isSubmitting}
          variant="contained"
          type="button"
          onClick={() => {
            validateForm().then(async (errors) => {
              if (Object.keys(errors).length === 0) {
                await handleNext(values, bag);
              } else {
                setTouched(setNestedObjectValues({...bag.initialValues, ...values}, true));
              }
            });
          }}
        >
          {lastStepSaveDraftButtonText}
        </SaveDraftButton>
      ) : null}
      <Button
        style={buttonStyle}
        disabled={isSubmitting}
        variant="contained"
        type="button"
        onClick={() => {
          if (lastStep && values.draftMode) {
            setFieldValue('draftMode', false);
          }
          validateForm().then(async (errors) => {
            if (Object.keys(errors).length === 0) {
              await handleNext(values, bag);
            } else {
              // this was not setting touched for properties in values that were undefined
              setTouched(setNestedObjectValues({...bag.initialValues, ...values}, true));
            }
          });
        }}
      >
        {lastStep ? lastStepButtonText : 'Next'}
      </Button>
    </Root>
  );
};

export default StepperFormActionSection;
