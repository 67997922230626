import React, {FC, useState, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import {AxiosResponse} from 'axios';
import {StaticButton} from '../Button';
import Dropzone from '../DragDrop/Dropzone';
import {Modal} from '.';
import BulkUploadErrorModal from './BulkUploadErrorModal';
import BulkUploadWarningModal from './BulkUploadWarningModal';

const TemplateLinkButton = styled(StaticButton)`
  padding: 0;
  line-height: unset;
  vertical-align: unset;
`;

type Props = {
  title: string;
  uploadUrl: string;
  open: boolean;
  onClose: () => void;
  onOpenTemplate: () => void;
  onComplete: (response?: AxiosResponse<any>, keepModalOpen?: boolean) => void;
  onReupload?: () => void;
  additionalData?: any[];
  children?: React.ReactNode;
};

const BulkUploadModal: FC<Props> = ({
  title,
  uploadUrl,
  additionalData = {},
  open,
  onClose,
  onOpenTemplate,
  onComplete,
  onReupload,
}) => {
  const [modalOpen, setModalOpen] = useState(open);
  const [uploadErrorModalOpen, setUploadErrorModalOpen] = useState(false);
  const [response, setResponse] = useState({});
  const [uploadWarningModalOpen, setUploadWarningModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  function onUploadError(error: any) {
    let response = error.response;
    setResponse(response);
    setModalOpen(false);
    setUploadErrorModalOpen(true);
  }
  function onUploadComplete(response: AxiosResponse<any>) {
    const warning = response?.data?.warning;
    const hasWarnings = Boolean(warning?.lineWarnings && warning.lineWarnings.length > 0);
    setModalOpen(!hasWarnings);
    setUploadWarningModalOpen(hasWarnings);
    onComplete(response, hasWarnings);
    if (hasWarnings) {
      setResponse(response);
    }
  }
  function renderSubtitle() {
    return (
      <div>
        <span>Use our handy </span>
        <TemplateLinkButton onClick={onOpenTemplate}>Excel templates</TemplateLinkButton>
        <span> to upload all your data in one go.</span>
      </div>
    );
  }

  const handleWarningUploadAgain = () => {
    setUploadWarningModalOpen(false);
    setModalOpen(true);
    onReupload?.();
  };

  const handleWarningContinue = () => {
    setUploadWarningModalOpen(false);
    setModalOpen(false);
  };

  return (
    <>
      <Modal title={title} open={modalOpen} onClose={onClose} renderSubtitle={renderSubtitle}>
        <Dropzone
          apiEndpoint={uploadUrl}
          additionalData={additionalData}
          onError={onUploadError}
          onComplete={onUploadComplete}
        />
      </Modal>
      <BulkUploadErrorModal
        open={uploadErrorModalOpen}
        response={response}
        onReupload={() => {
          setUploadErrorModalOpen(false);
          setModalOpen(true);
        }}
        onOpenTemplate={() => {
          setUploadErrorModalOpen(false);
          onOpenTemplate();
        }}
        onClose={() => {
          setUploadErrorModalOpen(false);
          onClose();
        }}
      />
      <BulkUploadWarningModal
        open={uploadWarningModalOpen}
        response={response}
        onReupload={handleWarningUploadAgain}
        onClose={handleWarningContinue}
      />
    </>
  );
};

export default BulkUploadModal;
