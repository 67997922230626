import React, {useState, useCallback, useMemo} from 'react';
import {useFormikContext} from 'formik';
import {AxiosResponse} from 'axios';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import Consts from '../../../app/Consts';
import {setMixAndMatchTypeChangeLoading} from '../../../app/dealsReducer';
import {useAppDispatch} from '../../../app/store';
import {api, put} from '../../../utils/Request';
import {isNullish} from '../../../utils';
import {EditMixAndMatchTypeResponse, InitialMixAndMatchFormValues} from '../../../types';
import FieldLabel from '../../Form/FieldLabel';
import SelectField from '../../Form/SelectField';
import GroupConfirmModal from '../GroupConfirmModal';
import {mnmDisabled} from '../mixAndMatchUtils';

const MixAndMatchTypeSelect = () => {
  const [typeChangeModalOpen, setTypeChangeModalOpen] = useState(false);
  const [lastType, setLastType] = useState<string | null | undefined>(null);

  const dispatch = useAppDispatch();

  const bag = useFormikContext<InitialMixAndMatchFormValues>();
  const {
    values: {type, id: mixAndMatchId, groups},
    setFieldValue,
  } = bag;

  const hasProducts = useMemo(
    () => groups?.some((group) => group?.products?.data && group?.products.data?.length > 0),
    [groups]
  );

  const options = Object.values(Consts.MixAndMatchTypeEnum).reduce(
    (acc: {value: string; label: string}[], type: string) => {
      if (!Consts.MixAndMatchTypeMapping[type].hide) {
        return [...acc, {value: type, label: Consts.MixAndMatchTypeMapping[type].label}];
      }
      return acc;
    },
    []
  );

  const selectedOption = useMemo(() => options.find((x) => x.value === type), [type, options]);
  const disabledFields = mnmDisabled(bag.values).fields;

  const fetchAndSetGroups = useCallback(
    async (nextType: string | null | undefined) => {
      if (!mixAndMatchId || !nextType) {
        return;
      }
      try {
        dispatch(setMixAndMatchTypeChangeLoading(true));
        const response: AxiosResponse<EditMixAndMatchTypeResponse> = await put(
          api(Consts.Api.MixAndMatchType.replace(':id', `${mixAndMatchId}`)),
          {
            type: nextType,
          }
        );
        // reset groups when changing type
        setFieldValue('groups', response.data.data);
        // backend sets amount to 0 as default value
        const saneAmount = response.data.amount === 0 ? null : response.data.amount;
        setFieldValue('amount', saneAmount);
        setFieldValue('amountType', response.data.amountType);
        const groupAmounts = response.data.data?.map(({id, amount, quantity, quantityLimit}) => ({
          id,
          ...(!amount && amount !== 0 ? {} : {amount}),
          ...(!quantity && quantity !== 0 ? {} : {quantity}),
          ...(!quantityLimit && quantityLimit !== 0 ? {} : {quantityLimit}),
        }));
        setFieldValue('groupAmounts', groupAmounts);
      } catch (error: any) {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      } finally {
        dispatch(setMixAndMatchTypeChangeLoading(false));
      }
    },
    [dispatch, mixAndMatchId, setFieldValue]
  );

  const handleSelectChange = async () => {
    setTypeChangeModalOpen(false);
    fetchAndSetGroups(type);
  };

  const handleChange = useCallback(
    (nextType: string | null) => {
      if (!isNullish(nextType) && nextType === type) {
        return;
      }
      setLastType(type);
      if (!type || !hasProducts) {
        fetchAndSetGroups(nextType);
      } else {
        setTypeChangeModalOpen(true);
      }
    },
    [type, fetchAndSetGroups, hasProducts]
  );

  return (
    <>
      <FieldLabel htmlFor="type">Select Mix and Match type</FieldLabel>
      <SelectField
        options={options}
        defaultOption={selectedOption}
        onChanged={handleChange}
        id="type"
        name="type"
        placeholder="Select"
        disabled={!mixAndMatchId || disabledFields.type}
        freeSolo={false}
        disableClearable
      />
      <GroupConfirmModal
        open={typeChangeModalOpen}
        onOk={handleSelectChange}
        onCancel={() => {
          setFieldValue('type', lastType);
          setTypeChangeModalOpen(false);
        }}
        title="Mix & Match Type Changed"
        body=" You have selected a new mix and match type. The groups details including products you have added
        will be deleted if you continue."
      />
    </>
  );
};

export default MixAndMatchTypeSelect;
