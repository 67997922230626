import {isFuture, isValid} from 'date-fns';
import Consts from '../../app/Consts';
import {InitialSPIVFormValues, ValidSPIVFormValues} from '../../types';

export const spivDisabled = ({
  status = '',
  claims = [],
  startAt,
}: InitialSPIVFormValues | ValidSPIVFormValues) => {
  const isCancellable = Boolean(
    claims?.every?.((c) => c?.status === Consts.ClaimStatusEnum.ReadyToProcess) ?? true
  );
  const isDeletable = isValid(new Date(startAt as string))
    ? isFuture(new Date(startAt as string))
    : true;
  const finishedStatus = status === Consts.AgreementStatusEnum.Finished;
  const finishedDisabled = finishedStatus && !isDeletable && !isCancellable;

  return {
    fields: {
      // page 1 details
      nartaSwitch: finishedDisabled,
      claimVendor: finishedDisabled,
      description: finishedDisabled,
      departmentNumber: finishedDisabled,
      startAt: finishedDisabled,
      endAt: finishedDisabled,
      locationCriteria: finishedDisabled,
      // page 2 values
      suppliers: finishedDisabled,
      // page 3 finance details
      claimDaysAfterExpiry: finishedDisabled,
      claimInterval: finishedDisabled,
      excludeReturns: finishedDisabled,
    },
    actions: {
      editSpivValues: finishedDisabled,
      addSpivValue: finishedDisabled,
      deleteSpivValue: finishedDisabled,
      bulkUploadValues: finishedDisabled,
      // call put spivs/:id on next step details form step one
      disableUpdateOnNextStepOne: finishedDisabled,
      // call post suppliers on next step values form step two
      disableUpdateOnNextStepTwo: finishedDisabled,
    },
  };
};
