import React, {useCallback, useState, useRef} from 'react';
import {styled} from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {useDropzone} from 'react-dropzone';
import axios from 'axios';
import {AlertType} from '../../app/AlertService';
import config from '../../app/Config';
import {post} from '../../utils/Request';
import {ErrorBox} from '../Alert';
import {OutlinedButton} from '../Button';
import {AttachmentsIcon} from '../Icons';

const PREFIX = 'FilesUploader';

export const classes = {
  cancelIcon: `${PREFIX}-cancelIcon`,
  circularProgress: `${PREFIX}-circularProgress`,
  colorPrimary: `${PREFIX}-colorPrimary`,
  emptyContainer: `${PREFIX}-emptyContainer`,
  headingButton: `${PREFIX}-headingButton`,
  headingContainer: `${PREFIX}-headingContainer`,
  root: `${PREFIX}-root`,
  subTitle: `${PREFIX}-subTitle`,
  title: `${PREFIX}-title`,
};

const StyledDropZoneContainer = styled('div')(({theme}) => ({
  width: '100%',
  padding: '30px 20px',
  border: '1px solid #dedede',
  background: '#f7f9fa padding-box',
  [`& .${classes.circularProgress}`]: {
    marginLeft: 5,
  },
  [`& .${classes.cancelIcon}`]: {
    color: theme.palette.error.main,
    fontSize: 30,
  },
}));

const StyledLinearProgress = styled(LinearProgress)(({theme}) => ({
  '&.MuiLinearProgress-root': {
    height: '1rem',
    borderRadius: '0.5rem',
  },
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
}));

const CancelToken = axios.CancelToken;
let source;

const EmptyDropZoneContainer = styled('div')`
  min-height: 364px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px 0;
`;
const ProgressContainer = styled('div')`
  display: flex;
  width: 80%;
  flex-direction: column;
  margin: auto;
  align-items: flex-start;
`;
const ChildrenContainer = styled('div')`
  margin-bottom: 15px;
`;
const ProgressBarContainer = styled('div')`
  display: flex;
  > div {
    flex: 1;
  }
  width: 100%;
  align-items: center;
`;

const ProgressMessageContainer = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  color: #626262;
  margin-bottom: 1rem;
`;

const DropzoneTitle = styled('div')`
  font-size: 20px;
  font-weight: 500;
`;
const DropzoneIcon = styled('div')`
  margin-bottom: 15px;
`;

const DragDropTitle = styled(DropzoneTitle)`
  margin-bottom: 15px;
`;

const CompleteMessage = 'Complete';
const ErrorMessage = 'Error happened';
const FileRejectionContainer = styled('div')`
  text-align: left;
  margin: 20px 0;
`;
const StyledOutlinedButton = styled(OutlinedButton)`
  margin-left: 0;
`;

const FilesUploader = ({
  onError = (error) => {},
  onComplete = (response) => {},
  apiEndpoint,
  additionalData = {},
  multiple = false,
  accept = {},
  children,
  heading = '',
  showHeadingButton = false,
  showBottomButton = true,
  title = 'Add Files',
  subTitle = '',
  noFiles = true,
  buttonLabel = 'Upload',
  continueAddButtonLabel = 'Add files',
  button: ButtonComponent = StyledOutlinedButton,
  icon: Icon = AttachmentsIcon,
  maxSizeInMb = Infinity, //size in MB
  style = {},
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState('PROGRESSS!!!s');
  const [files, setFiles] = useState([]);
  const [fileRejections, setFileRejections] = useState([]);
  const rejectionsRef = useRef(null);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      setFileRejections(fileRejections);
      if (acceptedFiles.length === 0) {
        if (rejectionsRef.current && rejectionsRef.current.scrollIntoView) {
          rejectionsRef.current.scrollIntoView({block: 'center'});
        }
        return;
      }

      const files = multiple ? acceptedFiles : acceptedFiles[0];
      setFiles(files);

      let data = new FormData();
      for (const file of files) {
        data.append('file', file);
      }
      data.append('data', additionalData);

      source = CancelToken.source();
      const requestConfig = {
        onUploadProgress: function (progressEvent) {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
          setProgressMessage(progress === 100 ? 'Processing...' : `${progress}% complete`);
        },
        cancelToken: source.token,
        params: {
          entityCode: config.entityCode,
        },
        // https://github.com/axios/axios/issues/4406
        transformRequest: (data) => data,
        cache: {
          update: {
            [apiEndpoint]: 'delete',
          },
        },
      };
      post(apiEndpoint, data, requestConfig)
        .then((response) => {
          setProgressMessage('');
          setFiles([]);
          onComplete(response);
          if (rejectionsRef.current && rejectionsRef.current.scrollIntoView) {
            rejectionsRef.current.scrollIntoView({block: 'center'});
          }
        })
        .catch(function (error) {
          if (axios.isCancel(error)) {
            return;
          }
          if (error) {
            setProgressMessage(ErrorMessage);
            setFiles([]);
            onError(error);
          }
        });
    },
    [apiEndpoint, onComplete, onError, additionalData, multiple]
  );

  const cancelUpload = () => {
    if (source) {
      source.cancel('Operation canceled by the user.');
    }
    setProgressMessage('');
    setFiles([]);
  };
  const {getRootProps, getInputProps, open} = useDropzone({
    onDrop,
    accept: accept,
    noClick: true,
    noKeyboard: true,
    multiple: multiple,
    maxSize: maxSizeInMb * 1024 * 1024,
  });
  function getEmptyDropZoneContainer() {
    if (noFiles) {
      const subtitle = subTitle
        ? subTitle
        : maxSizeInMb === Infinity
        ? null
        : `${maxSizeInMb} MB limit per file`;
      return (
        <EmptyDropZoneContainer className={classes.emptyContainer}>
          <DropzoneIcon>
            <Icon />
          </DropzoneIcon>
          <Stack direction="column" spacing={3} alignItems="center">
            <DragDropTitle className={classes.title}>{title}</DragDropTitle>
            {subtitle ? <Box sx={{fontSize: '1.125rem'}}>{subtitle}</Box> : null}
            <div>{getRejections()}</div>
            <ButtonComponent
              onClick={(event) => {
                event.preventDefault();
                open();
              }}
            >
              {buttonLabel}
            </ButtonComponent>
          </Stack>
        </EmptyDropZoneContainer>
      );
    }
  }

  function getRejections() {
    if (fileRejections.length > 0) {
      return (
        <FileRejectionContainer ref={rejectionsRef} className={classes.rejectionsContainer}>
          <ErrorBox
            alertTitle="The following files are not uploaded:"
            type={AlertType.Error}
            sx={{alignItems: 'start'}}
          >
            {fileRejections.map((rejection, index) => {
              const messages = rejection.errors.map((x) => {
                if (x.code === 'file-too-large') {
                  return `File is larger than ${maxSizeInMb} MB`;
                }
                return x.message;
              });
              return (
                <div key={index}>
                  {rejection.file.name}: {messages.join('; ')}
                </div>
              );
            })}
          </ErrorBox>
        </FileRejectionContainer>
      );
    }
  }

  function getHeading() {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{marginBottom: '2rem'}}
      >
        {heading ? <Typography variant="h5">{heading}</Typography> : null}
        {showHeadingButton ? (
          <ButtonComponent onClick={open} className={classes.headingButton}>
            {continueAddButtonLabel}
          </ButtonComponent>
        ) : null}
      </Stack>
    );
  }

  function getChildren() {
    if (!noFiles) {
      const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        open();
      };

      return (
        <>
          <ChildrenContainer className={classes.children}>{children}</ChildrenContainer>
          {files && files.length > 0 ? (
            <ProgressContainer>
              <DropzoneTitle>Uploading your file</DropzoneTitle>
              <ProgressBarContainer>
                <div>
                  <StyledLinearProgress variant="determinate" value={uploadProgress} />
                </div>
                <IconButton onClick={cancelUpload} size="large">
                  <CancelIcon classes={{root: classes.cancelIcon}} />
                </IconButton>
              </ProgressBarContainer>
              {progressMessage && (
                <ProgressMessageContainer>
                  <span>{progressMessage}</span>
                  {uploadProgress === 100 &&
                    progressMessage !== CompleteMessage &&
                    progressMessage !== ErrorMessage && (
                      <CircularProgress size={30} classes={{root: classes.circularProgress}} />
                    )}
                </ProgressMessageContainer>
              )}
            </ProgressContainer>
          ) : showBottomButton ? (
            <div className={classes.bottomButtonContainer}>
              <ButtonComponent onClick={handleClick} className={classes.bottomButton}>
                {continueAddButtonLabel}
              </ButtonComponent>
            </div>
          ) : null}
        </>
      );
    }
  }

  return (
    <StyledDropZoneContainer
      style={style?.container ?? {}}
      {...getRootProps()}
      className={classes.root}
      data-testid="dropzone"
    >
      <input {...getInputProps()} />
      {getHeading()}
      {getChildren()}
      {getEmptyDropZoneContainer()}
    </StyledDropZoneContainer>
  );
};

export default FilesUploader;
