import React, {FC, useCallback, useContext, useEffect, useState, useRef} from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import {AxiosResponse} from 'axios';
import * as R from 'ramda';
import * as Yup from 'yup';
import {useFormikContext} from 'formik';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import add from 'date-fns/add';
import {
  DateType,
  daysAfterTodayInTZ,
  daysBeforeTodayInTZ,
  daysBetweenInTZ,
  combineUtcDateTime,
  isDateInRange,
  isValidDate,
  formatDate,
} from '../../utils/DateUtils';
import {post, api, put, del, get} from '../../utils/Request';
import LoadingContext from '../../app/LoadingContext';
import {alertService, AlertType, defaultAlertId} from '../../app/AlertService';
import config from '../../app/Config';
import Consts from '../../app/Consts';
import {useAppSelector} from '../../app/store';
import {selectLoggedInStaffCode, selectConfigsData} from '../../app/selectors';
import {
  Config,
  DealFormValues,
  DealFormBag,
  DealAgreementResponse,
  EntityActionType,
  EditDealAgreementRequest,
  CreateDealAgreementRequest,
  ValidatedDealFormValues,
} from '../../types';
import InputField from '../Form/InputField';
import FieldLabel from '../Form/FieldLabel';
import FieldGrid from '../Form/FieldGrid';
import LocationField from '../Form/LocationField';
import NartaSwitch from '../Form/NartaSwitch';
import VendorSearchField from '../Form/Agolia/VendorSearchField';
import DatePickerField from '../Form/DatePickerField';
import FormStep from '../Form/FormStep';
import StepperFormActionSection from '../Form/StepperFormActionSection';
import SelectField from '../Form/SelectField';
import TimeField from '../Form/TimeField';
import {DealClaimVendorChangeConfirmation} from '../Modal';
import {ExpandButton} from '../Button';
import ActiveClaimWarning from '../Claim/ActiveClaimWarning';
import useDelayLoading from '../Hook/useDelayLoading';
import {ErrorBox} from '../Alert';

const PREFIX = 'StepDealDetails';

const classes = {
  hidden: `${PREFIX}-hidden`,
};

const Root = styled('div')(() => ({
  [`& .${classes.hidden}`]: {
    display: 'none',
  },
}));

export const noFutureStartAtValidationSchema = (
  configs: Config | undefined,
  startTime: DealFormValues['startTime']
) =>
  Yup.object().shape({
    startAt: Yup.date()
      .required(Consts.ValidationMessage.Required)
      .nullable()
      .typeError(Consts.ValidationMessage.Date.FormatError)
      .test('future start check', function (value) {
        if (value && isAfter(combineUtcDateTime(value, startTime), new Date())) {
          return this.createError({
            message: `The start date (${formatDate(value)}) cannot be set in the future`,
          });
        }
        return true;
      }),
  });

export const noFutureStartTimeValidationSchema = (startAt: DealFormValues['startAt']) =>
  Yup.object().shape({
    startTime: Yup.date()
      .nullable()
      .typeError(Consts.ValidationMessage.Time.FormatError)
      .test('start-time-validation', 'Start time cannot be in the future', (value) => {
        if (startAt) {
          const combinedStartDateTime = combineUtcDateTime(startAt, value);
          if (isValidDate(combinedStartDateTime)) {
            return isBefore(combinedStartDateTime, new Date());
          }
        }
        return true;
      }),
  });

type Props = {
  step: number;
  title: string;
  validationSchema?: any;
  totalStep: number;
  handleBack: (values: DealFormValues, bag: DealFormBag) => void;
  handleNext: (values: DealFormValues, bag: DealFormBag) => void;
  style?: React.CSSProperties;
};

const StepDealDetails: FC<Props> = ({step, title, totalStep, handleBack, handleNext}) => {
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const bag = useFormikContext<DealFormValues>();
  const initialStartDate = useRef<Date>();
  const initialEndDate = useRef<Date>();

  const {
    setFieldValue,
    values: {
      claimVendor,
      dealType,
      dealValueCount,
      departmentNumber,
      departments,
      endAt,
      endTime,
      hasActiveClaim,
      hasRaisedClaim,
      id,
      isFinalClaimRaised,
      promotionId,
      startAt,
      startTime,
    },
  } = bag;
  const [showTimePicker, setShowTimePicker] = useState<boolean>(!!startTime || !!endTime);
  const [showNoFutureStartInfo, setShowNoFutureStartInfo] = useState(false);
  const [showNoFutureStartError, setShowNoFutureStartError] = useState(false);
  const [departmentLoading, setDepartmentLoading] = useDelayLoading(false);
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);
  const configs = useAppSelector(selectConfigsData);

  const showStartDateDaysBeforeWarning =
    startAt && daysBeforeTodayInTZ(startAt) >= config.dealStartDateWarningDaysBeforeToday;
  const showStartDateDaysAfterWarning =
    startAt && daysAfterTodayInTZ(startAt) >= config.dealStartDateWarningDaysAfterToday;
  const showDateRangeWarning =
    startAt && endAt && daysBetweenInTZ(endAt, startAt) > config.dealDateRangeWarningDaysBetween;
  function updateFormValues(updatedValues: Partial<DealFormValues>) {
    R.forEachObjIndexed((value, key) => setFieldValue(key, value), updatedValues);
  }

  useEffect(() => {
    if (!initialStartDate.current && startAt) {
      initialStartDate.current = combineUtcDateTime(startAt, startTime);
    }
  }, [startAt, startTime]);

  useEffect(() => {
    if (!initialEndDate.current && endAt) {
      initialEndDate.current = combineUtcDateTime(endAt, endTime, true);
    }
  }, [endAt, endTime]);

  const dealIsActive =
    initialStartDate.current &&
    initialEndDate.current &&
    isDateInRange(initialStartDate.current, initialEndDate.current);

  const noFutureStartCriteriaMet =
    Boolean(promotionId) && dealType === Consts.StandardDealTypeEnum.Sales && !!dealIsActive;

  useEffect(() => {
    if (startAt) {
      const combinedStartAt = combineUtcDateTime(startAt, startTime);
      if (isValidDate(combinedStartAt)) {
        // show error if date set to future of deal that is Sales type, promo, and active (initial dates)
        const showError = noFutureStartCriteriaMet && isAfter(combinedStartAt, new Date());
        setShowNoFutureStartError(showError);
        if (endAt) {
          const combinedEndAt = combineUtcDateTime(endAt, endTime, true);
          if (isValidDate(combinedEndAt)) {
            // show info if deal is Sales type, promo, and active (initial dates)
            setShowNoFutureStartInfo(noFutureStartCriteriaMet);
          }
        }
      }
    }
  }, [noFutureStartCriteriaMet, startAt, startTime, endAt, endTime]);

  function getUpdatedFormValues(responseData: DealAgreementResponse) {
    return {
      id: responseData.id,
      claimVendorSuppliers: responseData.claimVendorSuppliers,
    };
  }

  useEffect(() => {
    let hasId = id && id > 0;
    if (!hasId) {
      setFieldValue('ownedByStaffCode', loggedInStaffCode);
      // for new deals, set default department to first buyer department
      get(api(Consts.Api.BuyerDepartments), {
        params: {entityCode: config.entityCode},
      })
        .then((response) => {
          if (response.data.length > 0) {
            setFieldValue('departmentNumber', response.data[0].number);
          }
        })
        .catch((error) => {
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        });
    }
  }, [setFieldValue, loggedInStaffCode, id]);

  useEffect(() => {
    if (departments.length === 0) {
      setDepartmentLoading(true);
      get(api(Consts.Api.Departments), {params: {entityCode: config.entityCode}})
        .then((response) => {
          const departments = response.data;
          setFieldValue('departments', departments);
        })
        .catch((error) => {
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        })
        .finally(() => {
          setDepartmentLoading(false);
        });
    }
  }, [setFieldValue, setDepartmentLoading, departments]);

  async function createOrEditDeal(values: ValidatedDealFormValues, bag: DealFormBag) {
    showLoading();
    const startAt = combineUtcDateTime(values.startAt, values.startTime);
    const endAt = combineUtcDateTime(values.endAt, values.endTime, true);
    if (values.id && values.id > 0) {
      const requestData: EditDealAgreementRequest = {
        description: values.description,
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
        startTimeSpecified: !!values.startTime,
        endTimeSpecified: !!values.endTime,
        claimVendorCode: values.claimVendor.code,
        locationCriteria: values.locationCriteria,
        ownedByStaffCode: values.ownedByStaffCode,
        lastUpdatedByStaffCode: loggedInStaffCode,
        departmentNumber: values.departmentNumber,
        isFinalClaimRaised: values.isFinalClaimRaised,
      };
      return await put(api(Consts.Api.DealAgreement.replace(':id', `${values.id}`)), requestData)
        .then((response: AxiosResponse<DealAgreementResponse>) => {
          const updatedValues = getUpdatedFormValues(response.data);
          updateFormValues(updatedValues);
          hideLoading();
          alertService.clear(defaultAlertId);
          handleNext({...values, ...updatedValues}, bag);
        })
        .catch((error) => {
          hideLoading();
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        });
    } else {
      const requestData: CreateDealAgreementRequest = {
        description: values.description,
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
        startTimeSpecified: !!values.startTime,
        endTimeSpecified: !!values.endTime,
        entityCode: config.entityCode,
        claimVendorCode: values.claimVendor.code,
        locationCriteria: values.locationCriteria,
        ownedByStaffCode: values.ownedByStaffCode,
        createdByStaffCode: loggedInStaffCode,
        departmentNumber: values.departmentNumber,
      };
      return await post(api(Consts.Api.DealAgreements), requestData)
        .then((response) => {
          const updatedValues = getUpdatedFormValues(response.data);
          updateFormValues(updatedValues);
          hideLoading();
          alertService.clear(defaultAlertId);
          handleNext({...values, ...updatedValues}, bag);
        })
        .catch((error) => {
          hideLoading();
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        });
    }
  }

  const onConfirmClaimVendorChange = useCallback(() => {
    if (!id) {
      return;
    }
    showLoading();
    del(api(Consts.Api.DealAgreementValues.replace(':id', `${id}`)))
      .then(() => {
        hideLoading();
        setFieldValue('dealValues', []);
        setFieldValue('dealValueCount', 0);
      })
      .catch((error) => {
        alertService.alert({
          id: defaultAlertId,
          ...{message: 'Failed to clear deal values', response: error.response},
        });
        hideLoading();
      });
  }, [hideLoading, showLoading, id, setFieldValue]);

  const defaultDepartment = departments?.find((x) => x.number === departmentNumber) ?? null;

  return (
    <Root>
      <FormStep step={step} title={title}>
        <ActiveClaimWarning
          entityActionType={EntityActionType.Deal}
          hasActiveClaim={hasActiveClaim}
          hasRaisedClaim={hasRaisedClaim}
        />
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="isNarta" fullWidth>
            Is this deal being processed through NARTA?
          </FieldLabel>
          <NartaSwitch
            disabled={hasRaisedClaim || isFinalClaimRaised}
            id="isNarta"
            name="claimVendor"
            confirm={dealValueCount > 0 && id ? DealClaimVendorChangeConfirmation : null}
            onConfirmChange={onConfirmClaimVendorChange}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="claimVendor">Claim Vendor</FieldLabel>
          <VendorSearchField
            id="claimVendor"
            name="claimVendor"
            disabled={
              hasRaisedClaim || isFinalClaimRaised || claimVendor.code === Consts.Narta.Code
            }
            fullWidth
            confirm={dealValueCount > 0 && id ? DealClaimVendorChangeConfirmation : null}
            onConfirmChange={onConfirmClaimVendorChange}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="description">Deal Description</FieldLabel>
          <InputField
            id="description"
            name="description"
            fullWidth
            placeholder="Add your description here"
            disabled={isFinalClaimRaised}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="departmentNumber">Department</FieldLabel>
          <SelectField
            loading={departmentLoading}
            options={departments}
            defaultOption={defaultDepartment}
            labelFunc={(x) => x.description}
            valueFunc={(x) => x.number}
            id="departmentNumber"
            name="departmentNumber"
            placeholder="Start typing a department"
            disabled={isFinalClaimRaised}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FieldLabel htmlFor="startAt" fullWidth>
                Start Date
              </FieldLabel>
              <DatePickerField
                dateType={DateType.RangeStart}
                id="startAt"
                name="startAt"
                maxDate={
                  noFutureStartCriteriaMet ? add(new Date(), {days: -1}).toISOString() : undefined
                }
                placeholder="Type or choose a start date"
                validationSchema={
                  noFutureStartCriteriaMet
                    ? noFutureStartAtValidationSchema(configs, startTime)
                    : undefined
                }
                fullWidth
                disabled={isFinalClaimRaised}
              />
            </Grid>
            <Grid item xs={6}>
              <FieldLabel htmlFor="endAt" fullWidth>
                End Date
              </FieldLabel>
              <DatePickerField
                dateType={DateType.RangeEnd}
                id="endAt"
                name="endAt"
                placeholder="Type or choose an end date"
                maxDate={Consts.Date.MaxEndDateMonthsFromNow}
                fullWidth
                disabled={isFinalClaimRaised}
              />
            </Grid>
          </Grid>
          {showStartDateDaysAfterWarning ||
          showStartDateDaysBeforeWarning ||
          showDateRangeWarning ? (
            <Grid item xl={12}>
              <ErrorBox type={AlertType.Warning}>
                {showStartDateDaysBeforeWarning ? (
                  <div>
                    The start date is equal to or more than{' '}
                    {config.dealStartDateWarningDaysBeforeToday} days before today.
                  </div>
                ) : null}
                {showStartDateDaysAfterWarning ? (
                  <div>
                    The start date is equal to or more than{' '}
                    {config.dealStartDateWarningDaysBeforeToday} days after today.
                  </div>
                ) : null}
                {showDateRangeWarning ? (
                  <div>
                    The difference between start date and end date is greater than{' '}
                    {config.dealDateRangeWarningDaysBetween} days.
                  </div>
                ) : null}
              </ErrorBox>
            </Grid>
          ) : null}
          {!showNoFutureStartError && showNoFutureStartInfo ? (
            <Grid item xl={12}>
              <ErrorBox type={AlertType.Info} data-testid="no-future-start-info">
                <div>
                  Start date or time in the future cannot be selected after a promotional deal has
                  started, please pick a date to continue. If this deal needs to end, please delete.
                </div>
              </ErrorBox>
            </Grid>
          ) : null}
          {showNoFutureStartError ? (
            <Grid item xl={12}>
              <ErrorBox type={AlertType.Error} data-testid="no-future-start-error">
                <div>
                  Sorry, you cannot set the start date or time in the future after a promotional
                  deal has started, please pick another date to continue. If this deal needs to end,
                  please delete
                </div>
              </ErrorBox>
            </Grid>
          ) : null}
          <ExpandButton
            onClick={() => setShowTimePicker(true)}
            className={showTimePicker ? classes.hidden : ''}
            sx={{paddingLeft: 0}}
            disabled={isFinalClaimRaised}
          >
            Add a start time and / or end time
          </ExpandButton>
        </FieldGrid>
        {showTimePicker ? (
          <Grid container spacing={1}>
            <FieldGrid item xs={6}>
              <FieldLabel htmlFor="startTime" fullWidth>
                Start Time
              </FieldLabel>
              <TimeField
                id="startTime"
                name="startTime"
                placeholder="Enter a start time"
                fullWidth
                validationSchema={
                  noFutureStartCriteriaMet ? noFutureStartTimeValidationSchema(startAt) : undefined
                }
              />
            </FieldGrid>
            <FieldGrid item xs={6}>
              <FieldLabel htmlFor="endTime" fullWidth>
                End Time
              </FieldLabel>
              <TimeField id="endTime" name="endTime" placeholder="Enter an end time" fullWidth />
            </FieldGrid>
          </Grid>
        ) : null}
        <FieldGrid item xs={12}>
          <FieldLabel>Location Assignment</FieldLabel>
          <LocationField
            id="locationCriteria"
            name="locationCriteria"
            disabled={isFinalClaimRaised}
          />
        </FieldGrid>
      </FormStep>
      <StepperFormActionSection
        handleBack={handleBack}
        handleNext={createOrEditDeal}
        step={step}
        totalStep={totalStep}
      />
    </Root>
  );
};

export default StepDealDetails;
