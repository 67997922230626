import * as Yup from 'yup';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import Consts from '../../../app/Consts';
import {combineUtcDateTime, isValidDate, formatDate} from '../../../utils/DateUtils';

export const stepMixAndMatchDetailsValidationSchema = Yup.object({
  description: Yup.string()
    .required(Consts.ValidationMessage.Required)
    .test(
      'non-printable-chars',
      Consts.ValidationMessage.DealNonPrintableCharsError,
      function (value) {
        // eslint-disable-next-line no-control-regex
        return !/[\u0000-\u001F\u007F]/g.test(value ?? '');
      }
    ),
  departments: Yup.array()
    .min(1, 'Select at least one department')
    .required(Consts.ValidationMessage.Required),
  startAt: Yup.date()
    .required(Consts.ValidationMessage.Required)
    .nullable()
    .typeError(Consts.ValidationMessage.Date.FormatError)
    .test(
      'cut-off date check',
      'The start date cannot be set before the cut-off date',
      function (value) {
        if (!value) {
          return true;
        }
        const lockDate = this.parent[Consts.ConfigNameEnum.DealLockDate];

        const cutoffDate = this.parent[Consts.ConfigNameEnum.DealCutoffDate];
        if (
          lockDate &&
          isAfter(new Date(), new Date(lockDate)) &&
          cutoffDate &&
          isBefore(new Date(value), new Date(cutoffDate))
        ) {
          return this.createError({
            message: `The start date cannot be set before the cut-off date (${formatDate(
              cutoffDate
            )})`,
          });
        }
        return true;
      }
    ),
  endAt: Yup.date()
    .min(Yup.ref('startAt'), Consts.ValidationMessage.Date.EndDateRangeError)
    .max(Consts.Date.MaxEndDateMonthsFromNow, Consts.ValidationMessage.Date.MaxEndDateRangeError)
    .required(Consts.ValidationMessage.Required)
    .nullable()
    .typeError(Consts.ValidationMessage.Date.FormatError)
    .test('end-at-validation', 'End date cannot be in the past', function (value) {
      const claimingStatus = this.resolve(Yup.ref('claimingStatus'));
      if (claimingStatus === Consts.AgreementStatusEnum.InProgress && !!value) {
        const endTime = this.resolve(Yup.ref('endTime')) as Date | null;
        const combinedEndDateTime = combineUtcDateTime(value, endTime ?? null);
        if (isValidDate(combinedEndDateTime)) {
          return isAfter(combinedEndDateTime, new Date());
        }
      }
      return true;
    }),
  receiptDescription: Yup.string()
    .max(40, 'Receipt description cannot exceed 40 characters')
    .test('required', Consts.ValidationMessage.Required, function (value) {
      return (value ?? '').trim().length > 0;
    })
    .test(
      'non-printable-chars',
      Consts.ValidationMessage.DealNonPrintableCharsError,
      function (value) {
        // eslint-disable-next-line no-control-regex
        return !/[\u0000-\u001F\u007F]/g.test(value ?? '');
      }
    ),
  startTime: Yup.date().nullable().typeError(Consts.ValidationMessage.Time.FormatError),
  endTime: Yup.date()
    .nullable()
    .typeError(Consts.ValidationMessage.Time.FormatError)
    .test(
      'start-end-time-validation',
      Consts.ValidationMessage.Time.EndTimeRangeError,
      function (value) {
        if (isValidDate(this.parent.startAt) && isValidDate(this.parent.endAt)) {
          return (
            !isValidDate(this.parent.startTime) ||
            combineUtcDateTime(this.parent.startAt, this.parent.startTime) <=
              combineUtcDateTime(this.parent.endAt, value, true)
          );
        }
        return true;
      }
    )
    .test('end-time-validation', 'End time cannot be in the past', function (value) {
      const claimingStatus = this.resolve(Yup.ref('claimingStatus'));
      const endAt = this.resolve(Yup.ref('endAt')) as Date | null;
      if (
        claimingStatus === Consts.AgreementStatusEnum.InProgress &&
        isValidDate(endAt) &&
        !!value
      ) {
        const combinedEndDateTime = combineUtcDateTime(endAt as Date, value);
        if (isValidDate(combinedEndDateTime)) {
          return isAfter(combinedEndDateTime, new Date());
        }
      }
      return true;
    }),
});
