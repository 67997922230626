import React, {FC, useMemo} from 'react';
import Tooltip from '@mui/material/Tooltip';
import CheckboxField from '../../components/Form/CheckboxField';
import {LocalDealValue} from './DealValuesTable';

type Props = {
  dealType: string;
  dealValues: LocalDealValue[];
  disabled?: boolean;
};

const GeneratePriceUpdateCheckbox: FC<Props> = ({dealType, dealValues, disabled}) => {
  const generatePricebookUpdateFieldName = 'generatePricebookUpdate';

  const show = useMemo(() => {
    const promoItems = dealValues?.filter((d) => !!d.promoPrice);
    const hasAnyPromoPricesSet = promoItems?.length > 0;
    const isSalesDeal = dealType === 'Sales';
    return isSalesDeal && hasAnyPromoPricesSet;
  }, [dealType, dealValues]);

  return (
    <>
      {show ? (
        <>
          <Tooltip
            placement="top-start"
            title={<h3>Whether this deal will trigger a pricebook update.</h3>}
          >
            <span>
              <CheckboxField
                id={generatePricebookUpdateFieldName}
                name={generatePricebookUpdateFieldName}
                label="Generate Pricebook Update"
                data-testid="generate-pricebook-checkbox"
                disabled={disabled}
              />
            </span>
          </Tooltip>
        </>
      ) : null}
    </>
  );
};

export default GeneratePriceUpdateCheckbox;
