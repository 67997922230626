import React, {FC, useMemo} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import {useFormikContext} from 'formik';
import {InputDetail} from '../../../app/Consts';
import {getDisplayAmountValue, getDisplayCommasValue} from '../../../utils/AmountUtils';
import {ValidMixAndMatchFormValues} from '../../../types';
import {mnmDisabled} from '../mixAndMatchUtils';

type Props = {
  inputDetail: InputDetail | undefined;
  groupId: number;
  errors?: {[field: string]: string};
};

const GroupAmountInputs: FC<Props> = ({inputDetail, groupId, errors}) => {
  const bag = useFormikContext<ValidMixAndMatchFormValues>();
  const {
    setFieldValue,
    values: {groups, groupAmounts = []},
  } = bag;
  const disabledFields = mnmDisabled(bag.values).fields;
  const group = groups?.find((x) => x.id === groupId);
  const discountAdornment = useMemo(() => {
    if (group?.amountType?.toLowerCase() === 'percentage') {
      return {endAdornment: <InputAdornment position="end">%</InputAdornment>};
    } else if (['setprice', 'discountamount'].includes(group?.amountType?.toLowerCase() ?? '')) {
      return {startAdornment: <InputAdornment position="start">$</InputAdornment>};
    } else {
      return {};
    }
  }, [group?.amountType]);

  const updateGroupAmounts = (id: number, name: string, value: string) => {
    // amount should be a number with 2 decimal places, quantity should be a positive whole number
    const regex = name === 'amount' ? /^(-?[\d,]*(?:\.\d{0,2})?)?$/ : /^[\d,]*$/;
    if (value === '' || regex.test(value)) {
      const nextGroupAmounts = [...groupAmounts];
      const groupIndex = nextGroupAmounts.findIndex((x) => x.id === id);
      const groupAmount = {id, [name]: value.replace(/,/g, '')};
      if (groupIndex === -1) {
        setFieldValue('groupAmounts', [...nextGroupAmounts, groupAmount]);
      } else {
        nextGroupAmounts.splice(groupIndex, 1, {
          ...nextGroupAmounts[groupIndex],
          ...groupAmount,
        });
        setFieldValue('groupAmounts', nextGroupAmounts);
      }
    }
  };

  const groupItemIndex = groupAmounts.findIndex((x) => x.id === groupId);
  const {showAmount, showQuantity, showQuantityLimit} = inputDetail ?? {};
  const noInputs = !showAmount && !showQuantity && !showQuantityLimit;
  if (!inputDetail || !group || noInputs) {
    return null;
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    const {value} = event.target;
    updateGroupAmounts(groupId, name, value);
  };

  const handleOnBlurFormat = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    const {value} = event.target;
    if (value) {
      updateGroupAmounts(groupId, name, getDisplayAmountValue(value.replace(/,/g, '')));
    }
  };

  return (
    <Grid container direction="row" spacing={1} justifyContent="space-between">
      {showAmount ? (
        <Grid item xs={6} gap={1}>
          <Typography variant="subtitle2">Benefit</Typography>
          <OutlinedInput
            fullWidth
            placeholder="Discount"
            onChange={(e) => handleChange(e, 'amount')}
            onBlur={(e) => handleOnBlurFormat(e, 'amount')}
            value={getDisplayCommasValue(
              groupAmounts[groupItemIndex]?.amount ?? group.amount ?? ''
            )}
            error={!!errors?.amount}
            {...discountAdornment}
            disabled={disabledFields.groups.amount}
          />
          {!!errors?.amount ? <FormHelperText error>{errors.amount}</FormHelperText> : null}
        </Grid>
      ) : null}
      {showQuantity ? (
        <Grid item xs={6} gap={1}>
          <Typography variant="subtitle2">Quantity</Typography>
          <OutlinedInput
            fullWidth
            placeholder="Quantity"
            onChange={(e) => handleChange(e, 'quantity')}
            value={getDisplayCommasValue(
              groupAmounts[groupItemIndex]?.quantity ?? group.quantity ?? ''
            )}
            error={!!errors?.quantity}
            disabled={disabledFields.groups.quantity}
          />
          {!!errors?.quantity ? <FormHelperText error>{errors.quantity}</FormHelperText> : null}
        </Grid>
      ) : null}
      {showQuantityLimit ? (
        <Grid xs={6} item gap={1}>
          <Typography variant="subtitle2">Quantity Limit</Typography>
          <OutlinedInput
            fullWidth
            placeholder="Quantity Limit"
            onChange={(e) => handleChange(e, 'quantityLimit')}
            value={getDisplayCommasValue(
              groupAmounts[groupItemIndex]?.quantityLimit ?? group.quantityLimit ?? ''
            )}
            error={!!errors?.quantityLimit}
            disabled={disabledFields.groups.quantityLimit}
          />
          {!!errors?.quantityLimit ? (
            <FormHelperText error>{errors.quantityLimit}</FormHelperText>
          ) : null}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default GroupAmountInputs;
